import React from "react";
import Modal from "react-modal";

import MainContext from "../context/MainContext";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { addDays, format, getDate, getMonth } from "date-fns";

Modal.setAppElement("#root");

const DisbsadDetail = ({ detailModalShow, closeDetailModal, sp }) => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [tableData, setTableData] = useState([]);
  const startDate = format(new Date(datePicker), "yyyy-MM-dd");

  useEffect(() => {
    const getdata = async () => {
      const response = await fetch(
        //`https://data.elexon.co.uk/bmrs/api/v1/balancing/nonbm/disbsad/details?settlementDate=2024-09-25&settlementPeriod=39&format=json`
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/nonbm/disbsad/details?settlementDate=${startDate}&settlementPeriod=${sp}&format=json`
      );
      let unfilteredData1 = await response.json();
      let data1 = unfilteredData1.data;

      console.log(data1);

      const fontSize = {
        fontSize: "12px",
        backgroundColor: "#2e86c1",
        position: "sticky",
        top: "-10px",
      };
      const fontSize2 = { fontSize: "12px" };
      const buttonStyle = { fontSize: "12px", height: "18px" };
      const content = (
        <>
          <div className="container">
            <table className="table table-condensed table-striped table-bordered">
              <thead style={{ position: "sticky top: 0" }}>
                <tr>
                  <th style={fontSize}>Id</th>
                  <th style={fontSize}>Cost</th>
                  <th style={fontSize}>Volume</th>
                  <th style={fontSize}>Price</th>
                  <th style={fontSize}>SO Flag</th>
                  <th style={fontSize}>Stor Flag</th>
                  <th style={fontSize}>Party Id</th>
                  <th style={fontSize}>Asset Id</th>
                  <th style={fontSize}>Is Tendered</th>
                  <th style={fontSize}>Service</th>
                </tr>
              </thead>
              <tbody>
                {data1?.map((data, i) => (
                  <tr key={i}>
                    <td style={fontSize2}>{data.id}</td>
                    {/* <td style={fontSize2}>{data.createdDateTime}</td> */}
                    <td style={fontSize2}>{data.cost}</td>

                    <td style={fontSize2}>{data.volume}</td>
                    <td style={fontSize2}>{data.price}</td>
                    <td style={fontSize2}>{data.soFlag ? "True" : "False"}</td>
                    <td style={fontSize2}>
                      {data.storFlag ? "True" : "False"}
                    </td>

                    <td style={fontSize2}>{data.partyId}</td>
                    <td style={fontSize2}>{data.assetId}</td>
                    <td style={fontSize2}>
                      {data.isTendered ? "True" : "False"}
                    </td>
                    <td style={fontSize2}>{data.service}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
      setTableData(content);

      setLoading(false);
    };

    getdata();
  }, [datePicker, sp]);
  console.log(detailModalShow);
  return (
    <Modal isOpen={detailModalShow}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="container">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-11">
                    Settlement Period - {sp}
                    <br />
                    <br />
                  </div>
                  <div className="col-md-1 text-end ">
                    <button
                      className="btn btn-primary "
                      onClick={closeDetailModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body">{tableData}</div>
          <div className="container">
            <div className="modal-footer">
              <button className="btn btn-primary" onClick={closeDetailModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DisbsadDetail;
