import React from "react";
import { useState, useEffect, useContext } from "react";
import MainContext from "../context/MainContext";
import { addDays, format, getDate, getMonth } from "date-fns";
import { ClipLoader } from "react-spinners";
import { getTimezoneOffset } from "date-fns-tz";

const Warnings = () => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const localStartDate = format(new Date(datePicker), "yyyy-MM-dd");
      let startDateTzDiff =
        getTimezoneOffset("Europe/Dublin", localStartDate) / 1000 / 60 / 60;
      const localEndDate = format(
        new Date(addDays(datePicker, 1)),
        "yyyy-MM-dd"
      );
      let endDateTzDiff =
        getTimezoneOffset("Europe/Dublin", localEndDate) / 1000 / 60 / 60;

      let startDate;
      let endDate;
      if (startDateTzDiff === 1) {
        startDate =
          format(new Date(addDays(localStartDate, -1)), "yyyy-MM-dd") +
          "T23%3A00z";
      } else {
        startDate =
          format(new Date(localStartDate), "yyyy-MM-dd") + "T00%3A00z";
      }
      if (endDateTzDiff === 1) {
        endDate =
          format(new Date(addDays(localEndDate, -1)), "yyyy-MM-dd") +
          "T22%3A30z";
      } else {
        endDate =
          format(new Date(addDays(localEndDate, -1)), "yyyy-MM-dd") +
          "T23%3A30z";
      }
      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/system/warnings?publishDateTimeFrom=${startDate}&publishDateTimeTo=${endDate}&format=json
`
      );
      let data1 = await response.json();
      console.log(data1);

      const modifyString = (text) => {
        let modText = text
          .replaceAll("\r\\n\r\\n\r\\n", "\n ")
          .replaceAll("\r\\n", "\n")
          .replaceAll("\r\\n\r\\n", "\n")
          .replaceAll("\\n\\n", "\n");

        return modText;
      };

      const fontSize = {
        fontSize: "14px",
        backgroundColor: "#2e86c1 ",
      };
      const fontSize2 = { fontSize: "14px", whiteSpace: "pre-wrap" };
      const content = (
        <>
          <div className={"container-fluid"}>
            <table className="table table-condensed table-striped table-bordered">
              <thead
                style={{
                  position: "sticky top: 0",
                }}
              >
                <tr>
                  <th style={fontSize}>
                    <p className={"py-0 my-0"}>Publish Time</p>
                  </th>
                  <th style={fontSize}>Warning Type</th>
                  <th style={fontSize}>Warning Message</th>
                </tr>
              </thead>
              <tbody>
                {data1.data.map((data, i) => (
                  <tr key={i}>
                    <td style={fontSize2}>
                      {format(new Date(data.publishTime), "yyyy-MM-dd HH:mm")}
                    </td>
                    <td style={fontSize2}>{data.warningType}</td>
                    <td
                      style={fontSize2}
                      className={{ whiteSpace: "pre-wrap" }}
                    >
                      {modifyString(data.warningText)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
      setTableData(content);
      setLoading(false);
    };
    getData();
  }, [datePicker]);

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader loading={loading} />{" "}
        </div>
      ) : (
        <div>
          <div>{tableData}</div>
        </div>
      )}
    </>
  );
};

export default Warnings;
