import React from "react";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import MainContext from "../context/MainContext";
import { addDays, format, getDate, getMonth } from "date-fns";
import { ClipLoader } from "react-spinners";
import { getTimezoneOffset } from "date-fns-tz";
import { useNavigate, Link, useLocation, Outlet } from "react-router-dom";
import PriceDetail from "./PriceDetail";

const Sbpsp2 = () => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [tableData, setTableData] = useState([]);
  const [detailModalShow, setDetailModalShow] = useState(false);
  const [sp, setSp] = useState(1);
  const [code, setCode] = useState("");
  const [price, setPrice] = useState("");

  const navigate = useNavigate();
  //const [options, setOptions] = useState([]);

  useEffect(() => {
    setLoading(true);
    const startDate = format(new Date(datePicker), "yyyy-MM-dd");
    const endDate = format(new Date(addDays(startDate, 1)), "yyyy-MM-dd");
    let tzDiff = getTimezoneOffset("Europe/Dublin", startDate) / 1000 / 60 / 60;
    let newStartDate;
    let newEndDate;
    if (tzDiff === 1) {
      newStartDate =
        format(new Date(addDays(startDate, -1)), "yyyy-MM-dd") + "T23%3A00z";
      newEndDate = startDate + "T22%3A30z";
    } else {
      newStartDate = startDate + "T00%3A00z";
      newEndDate = endDate + "T23%3A30z";
    }

    const getData = async () => {
      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/system-prices/${startDate}?format=json`
      );
      let unfilteredData1 = await response.json();
      let data1 = unfilteredData1.data.sort(
        (a, b) => new Date(b.startTime) - new Date(a.startTime)
      );
      console.log(data1);

      const fontSize = {
        fontSize: "12px",
        backgroundColor: "#2e86c1",
        position: "sticky",
        top: "50px",
      };
      const fontSize2 = { fontSize: "12px" };
      const buttonStyle = { fontSize: "12px", height: "18px" };
      const content = (
        <>
          <div className={"container-fluid"}>
            <table className="table table-condensed table-striped table-bordered">
              <thead style={{ position: "sticky top: 0" }}>
                <tr>
                  <th style={fontSize}>Settlement Period Click for Detail</th>
                  {/* <th style={fontSize}>Created Date Time</th> */}
                  <th style={fontSize}>System Sell Price</th>
                  <th style={fontSize}>System Buy Price</th>
                  <th style={fontSize}>Bisbsad Defaulted</th>
                  <th style={fontSize}>Derivation Code</th>
                  <th style={fontSize}>Reserve Scarcity Price</th>
                  <th style={fontSize}>NIV</th>
                  <th style={fontSize}>Sell Price Adjustment</th>
                  <th style={fontSize}>Buy Price Adjustment</th>
                  <th style={fontSize}>Replacement Price</th>
                  <th style={fontSize}>Replacement Price Reference Volume</th>
                  <th style={fontSize}>Total Accepted Offer Volume</th>
                  <th style={fontSize}>Total Accepted Bid Volume</th>
                  <th style={fontSize}>Total Adjustment Sell Volume</th>
                  <th style={fontSize}>Total Adjustment Buy Volume</th>
                  <th style={fontSize}>
                    Total System Tagged Accepted Offer Volume
                  </th>
                  <th style={fontSize}>
                    Total System Tagged Accepted Bid Volume
                  </th>
                  <th style={fontSize}>
                    Total System Tagged Adjustment Sell Volume
                  </th>
                  <th style={fontSize}>
                    Total System Tagged Adjustment Buy Volume
                  </th>
                </tr>
              </thead>
              <tbody>
                {data1?.map((data, i) => (
                  <tr key={i}>
                    <td style={fontSize2}>
                      {
                        <button
                          style={buttonStyle}
                          className="btn btn-primary pt-0"
                          onClick={() => {
                            detailClicked(
                              data.settlementPeriod,
                              data.priceDerivationCode,
                              Math.round(data.systemSellPrice * 1000) / 1000
                            );
                          }}
                        >
                          {data.settlementPeriod}
                        </button>
                      }
                    </td>
                    {/* <td style={fontSize2}>{data.createdDateTime}</td> */}
                    <td style={fontSize2}>
                      {data.systemSellPrice === null
                        ? data.systemSellPrice
                        : Math.round(data.systemSellPrice * 1000) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.systemBuyPrice === null
                        ? data.systemBuyPrice
                        : Math.round(data.systemBuyPrice * 1000) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.bsadDefaulted ? "True" : "False"}
                    </td>
                    <td style={fontSize2}>{data.priceDerivationCode}</td>
                    <td style={fontSize2}>{data.reserveScarcityPrice}</td>
                    <td style={fontSize2}>
                      {data.netImbalanceVolume === null
                        ? data.netImbalanceVolume
                        : Math.round(data.netImbalanceVolume * 1000) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.sellPriceAdjustment === null
                        ? data.sellPriceAdjustment
                        : Math.round(data.sellPriceAdjustment * 1000) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.buyPriceAdjustment === null
                        ? data.buyPriceAdjustment
                        : Math.round(data.buyPriceAdjustment * 1000) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.replacementPrice === null
                        ? data.replacementPrice
                        : Math.round(data.replacementPrice * 1000) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.replacementPriceReferenceVolume === null
                        ? data.replacementPriceReferenceVolume
                        : Math.round(
                            data.replacementPriceReferenceVolume * 1000
                          ) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.totalAcceptedOfferVolume === null
                        ? data.totalAcceptedOfferVolume
                        : Math.round(data.totalAcceptedOfferVolume * 1000) /
                          1000}
                    </td>
                    <td style={fontSize2}>
                      {data.totalAcceptedBidVolume === null
                        ? data.totalAcceptedBidVolume
                        : Math.round(data.totalAcceptedBidVolume * 1000) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.totalAdjustmentSellVolume === null
                        ? data.totalAdjustmentSellVolume
                        : Math.round(data.totalAdjustmentSellVolume * 1000) /
                          1000}
                    </td>
                    <td style={fontSize2}>
                      {data.totalAdjustmentBuyVolume === null
                        ? data.totalAdjustmentBuyVolume
                        : Math.round(data.totalAdjustmentBuyVolume * 1000) /
                          1000}
                    </td>
                    <td style={fontSize2}>
                      {data.totalSystemTaggedAcceptedOfferVolume === null
                        ? data.totalSystemTaggedAcceptedOfferVolume
                        : Math.round(
                            data.totalSystemTaggedAcceptedOfferVolume * 1000
                          ) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.totalSystemTaggedAcceptedBidVolume === null
                        ? data.totalSystemTaggedAcceptedBidVolume
                        : Math.round(
                            data.totalSystemTaggedAcceptedBidVolume * 1000
                          ) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.totalSystemTaggedAdjustmentSellVolume === null
                        ? data.totalSystemTaggedAdjustmentSellVolume
                        : Math.round(
                            data.totalSystemTaggedAdjustmentSellVolume * 1000
                          ) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.totalSystemTaggedAdjustmentBuyVolume === null
                        ? data.totalSystemTaggedAdjustmentBuyVolume
                        : Math.round(
                            data.totalSystemTaggedAdjustmentBuyVolume * 1000
                          ) / 1000}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
      setTableData(content);

      setLoading(false);
    };

    getData();
  }, [datePicker]);

  const detailClicked = (e, f, g) => {
    console.log(e);
    setSp(e);
    setCode(f);
    setPrice(g);
    setDetailModalShow(true);
    //navigate(`/dash/bookings/`);
  };
  const closeDetailModal = () => {
    setDetailModalShow(false);
  };
  const options = {
    allowHtml: true,
    showRowNumber: false,
  };

  const formatters = [
    {
      column: 1,
      options: {
        prefix: "$",
        negativeColor: "red",
        negativeParens: true,
      },
    },
  ];

  // const customStyles = {
  //   content: {
  //     top: "15%",
  //     left: "20%",
  //     right: "auto",
  //     bottom: "auto",
  //     marginRight: "-50%",
  //     //transform: "translate(-50%, -50%)",
  //   },
  // };

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader loading={loading} />{" "}
        </div>
      ) : (
        <>
          <div>{tableData}</div>
          <PriceDetail
            detailModalShow={detailModalShow}
            closeDetailModal={closeDetailModal}
            sp={sp}
            price={price}
            code={code}
          />
        </>
      )}
    </>
  );
};

export default Sbpsp2;
