import React from "react";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import MainContext from "../context/MainContext";
import { addDays, format, getDate, getMonth } from "date-fns";
import { ClipLoader } from "react-spinners";
import { getTimezoneOffset } from "date-fns-tz";

const Uou2t3yw = () => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [content, setContent] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/datasets/UOU2T3YW?fuelType=${id}&format=json`
      );
      const uou2t3yw = await response.json();
      console.log(uou2t3yw);
      let bmuList = [];
      uou2t3yw.data.forEach((item) => {
        if (!bmuList.includes(item.bmUnit) && item.bmUnit !== null) {
          bmuList.push(item.bmUnit);
        }
        if (
          !bmuList.includes(item.nationalGridBmUnit) &&
          item.bmUnit === null
        ) {
          bmuList.push(item.nationalGridBmUnit);
        }
      });
      bmuList.sort();
      console.log(bmuList);
      const getChart = async (i, bmu, uou2t3yw) => {
        const bmuData = uou2t3yw.data.filter(
          (x) => x.bmUnit === bmu || x.nationalGridBmUnit === bmu
        );
        let chartData = [
          [
            { type: "string", label: "Year Week No" },
            { type: "number", label: "Output Usable" },
          ],
        ];
        let temp = [];
        for (let a = 0; a < bmuData.length; a++) {
          temp = [];
          temp.push(bmuData[a].year + " " + bmuData[a].week);
          temp.push(bmuData[a].outputUsable);
          chartData.push(temp);
        }

        const options = {
          title: bmu,

          width: 495,
          height: 300,
          series: {
            0: {
              type: "bars",
            },
          },
          hAxis: {
            textStyle: { fontSize: 10 },
            title: "Date",
          },
          vAxes: {
            0: { title: "Margin MW" },
          },
          chartArea: { backgroundColor: "#fffff0" },
        };

        return (
          <div
            key={i}
            style={{
              height: "340px",
              width: "500px",
              border: "1px solid #DCDCDC",
              float: "left",
            }}
          >
            <Chart chartType="LineChart" options={options} data={chartData} />
          </div>
        );
      };

      let chart;
      let promises = [];

      for (let i = 0; i < bmuList.length; i++) {
        promises.push(getChart(i, bmuList[i], uou2t3yw));
      }
      Promise.all(promises).then((arrOfResults) => {
        setContent([]);
        for (let i = 0; i < arrOfResults.length; i++) {
          setContent((current) => [...current, arrOfResults[i]]);
        }
      });
      setLoading(false);
    };
    getData();
  }, [id]);

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader loading={loading} />{" "}
        </div>
      ) : (
        <div>{content}</div>
      )}
    </>
  );
};

export default Uou2t3yw;
