import React from "react";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import MainContext from "../context/MainContext";
import { addDays, format, getDate, getMonth } from "date-fns";
import { ClipLoader } from "react-spinners";
import { getTimezoneOffset } from "date-fns-tz";

const Oc2Margin2_156 = () => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/datasets/OCNMF3Y2?format=json`
      );
      const oc2 = await response.json();

      let chartData = [
        [
          { type: "string", label: "Year Week No" },
          { type: "number", label: "Margin" },
        ],
      ];
      let temp = [];
      for (let a = 0; a < oc2.data.length; a++) {
        temp = [];
        temp.push(oc2.data[a].year + " " + oc2.data[a].week);
        temp.push(oc2.data[a].margin);
        chartData.push(temp);
      }
      setData(chartData);
      setLoading(false);
    };

    getData();
  }, []);

  const options = {
    title: "OCNMF3Y2 - 2 to 156 Weeks Ahead Demand Margin Forcast ",

    height: "100%",
    width: "100%",
    series: {
      0: {
        type: "bars",
      },
    },
    hAxis: {
      textStyle: { fontSize: 10 },
      title: "Year Week No",
    },
    vAxes: {
      0: { title: "Margin MW" },
    },
    chartArea: { backgroundColor: "#fffff0" },
  };

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader loading={loading} />{" "}
        </div>
      ) : (
        <>
          <div className="row">
            <div
              className="d-flex col md-12"
              style={{
                border: "1px solid #DCDCDC",
                height: "810px",
                width: "1750px",
                float: "none",
              }}
            >
              <Chart chartType="LineChart" options={options} data={data} />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Oc2Margin2_156;
