import React from "react";
import Modal from "react-modal";
import MainContext from "../context/MainContext";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { addDays, format, getDate, getMonth } from "date-fns";
import { ClipLoader } from "react-spinners";

Modal.setAppElement("#root");

const DynamicDataModal = ({ dynamicModalShow, closeDynamicModal, bmuName }) => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [rure, setRure] = useState();
  const [rdre, setRdre] = useState();
  const [ruri, setRuri] = useState();
  const [rdri, setRdri] = useState();
  const [sel, setSel] = useState();
  const [sil, setSil] = useState();
  const [ndz, setNdz] = useState();
  const [mzt, setMzt] = useState();
  const [mnzt, setMnzt] = useState();
  const [nto, setNto] = useState();
  const [ntb, setNtb] = useState();
  const [mdv, setMdv] = useState();
  const [mdp, setMdp] = useState();
  const [dataShow, setDataShow] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setDataShow(true);
      const getRure = async (startDate, endDate) => {
        console.log(startDate, endDate, bmuName);
        const response = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/datasets/RURE/stream?from=${startDate}T00%3A00&to=${endDate}T00%3A00&bmUnit=${bmuName}&format=json`
        );

        return await response.json();
      };
      const getRdre = async (startDate, endDate) => {
        console.log(startDate, endDate, bmuName);
        const response = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/datasets/RDRE/stream?from=${startDate}T00%3A00&to=${endDate}T00%3A00&bmUnit=${bmuName}&format=json`
        );

        return await response.json();
      };
      const getRuri = async (startDate, endDate) => {
        console.log(startDate, endDate, bmuName);
        const response = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/datasets/RURI/stream?from=${startDate}T00%3A00&to=${endDate}T00%3A00&bmUnit=${bmuName}&format=json`
        );

        return await response.json();
      };
      const getRdri = async (startDate, endDate) => {
        console.log(startDate, endDate, bmuName);
        const response = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/datasets/RDRI/stream?from=${startDate}T00%3A00&to=${endDate}T00%3A00&bmUnit=${bmuName}&format=json`
        );

        return await response.json();
      };
      const getSel = async (startDate, endDate) => {
        console.log(startDate, endDate, bmuName);
        const response = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/datasets/SEL/stream?from=${startDate}T00%3A00&to=${endDate}T00%3A00&bmUnit=${bmuName}&format=json`
        );

        return await response.json();
      };
      const getNdz = async (startDate, endDate) => {
        console.log(startDate, endDate, bmuName);
        const response = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/datasets/NDZ/stream?from=${startDate}T00%3A00&to=${endDate}T00%3A00&bmUnit=${bmuName}&format=json`
        );

        return await response.json();
      };

      const getMzt = async (startDate, endDate) => {
        console.log(startDate, endDate, bmuName);
        const response = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/datasets/MZT/stream?from=${startDate}T00%3A00&to=${endDate}T00%3A00&bmUnit=${bmuName}&format=json`
        );

        return await response.json();
      };

      const getMnzt = async (startDate, endDate) => {
        console.log(startDate, endDate, bmuName);
        const response = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/datasets/MNZT/stream?from=${startDate}T00%3A00&to=${endDate}T00%3A00&bmUnit=${bmuName}&format=json`
        );

        return await response.json();
      };

      const getSil = async (startDate, endDate) => {
        console.log(startDate, endDate, bmuName);
        const response = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/datasets/SIL/stream?from=${startDate}T00%3A00&to=${endDate}T00%3A00&bmUnit=${bmuName}&format=json`
        );

        return await response.json();
      };

      const getNtb = async (startDate, endDate) => {
        console.log(startDate, endDate, bmuName);
        const response = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/datasets/NTB/stream?from=${startDate}T00%3A00&to=${endDate}T00%3A00&bmUnit=${bmuName}&format=json`
        );

        return await response.json();
      };

      const getNto = async (startDate, endDate) => {
        console.log(startDate, endDate, bmuName);
        const response = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/datasets/NTO/stream?from=${startDate}T00%3A00&to=${endDate}T00%3A00&bmUnit=${bmuName}&format=json`
        );

        return await response.json();
      };

      // const getMdv = async (startDate, endDate) => {
      //   console.log(startDate, endDate, bmuName);
      //   const response = await fetch(
      //     `https://data.elexon.co.uk/bmrs/api/v1/datasets/MDV/stream?from=${startDate}T00%3A00&to=${endDate}T00%3A00&bmUnit=${bmuName}&format=json`
      //   );

      //   return await response.json();
      // };

      // const getMdp = async (startDate, endDate) => {
      //   console.log(startDate, endDate, bmuName);
      //   const response = await fetch(
      //     `https://data.elexon.co.uk/bmrs/api/v1/datasets/MDP/stream?from=${startDate}T00%3A00&to=${endDate}T00%3A00&bmUnit=${bmuName}&format=json`
      //   );

      //   return await response.json();
      // };

      const numDays = 500;
      if (bmuName.length > 0) {
        let preDayCheck = false;
        let endDaycheck = new Date(addDays(datePicker, 1));
        let startDaycheck = new Date(datePicker);
        let endDate = format(new Date(addDays(datePicker, 1)), "yyyy-MM-dd");
        let startDate = "2003-01-01";

        const rdreData = await getRdre(startDate, endDate);
        console.log(rdreData);

        const rdreTableData = [
          ["Date Time", "Rate1", "Elbow2", "Rate2", "Elbow3", "Rate3"],
        ];
        let temp = [];
        preDayCheck = false;
        for (let a = 0; a < rdreData.length; a++) {
          if (
            new Date(rdreData[a].time) <= endDaycheck &&
            preDayCheck === false
          ) {
            temp = [];

            temp.push(format(new Date(rdreData[a].time), "yyyy-MM-dd  HH:mm"));
            temp.push(rdreData[a].rate1);
            temp.push(rdreData[a].elbow2);
            temp.push(rdreData[a].rate2);
            temp.push(rdreData[a].elbow3);
            temp.push(rdreData[a].rate3);
            rdreTableData.push(temp);
          }
          if (new Date(rdreData[a].time) < startDaycheck) {
            preDayCheck = true;
          }
        }
        setRdre(rdreTableData);

        preDayCheck = false;

        const rureData = await getRure(startDate, endDate);
        console.log(rureData);

        const rureTableData = [
          ["Date Time", "Rate1", "Elbow2", "Rate2", "Elbow3", "Rate3"],
        ];
        temp = [];
        for (let a = 0; a < rureData.length; a++) {
          if (
            new Date(rureData[a].time) <= endDaycheck &&
            preDayCheck === false
          ) {
            temp = [];
            temp.push(format(new Date(rureData[a].time), "yyyy-MM-dd  HH:mm"));
            temp.push(rureData[a].rate1);
            temp.push(rureData[a].elbow2);
            temp.push(rureData[a].rate2);
            temp.push(rureData[a].elbow3);
            temp.push(rureData[a].rate3);
            rureTableData.push(temp);
          }
          if (new Date(rureData[a].time) < startDaycheck) {
            preDayCheck = true;
          }
        }
        setRure(rureTableData);

        const rdriData = await getRdri(startDate, endDate);
        console.log(rdriData);

        const rdriTableData = [
          ["Date Time", "Rate1", "Elbow2", "Rate2", "Elbow3", "Rate3"],
        ];
        temp = [];
        preDayCheck = false;
        for (let a = 0; a < rdriData.length; a++) {
          if (
            new Date(rdriData[a].time) <= endDaycheck &&
            preDayCheck === false
          ) {
            temp = [];

            temp.push(format(new Date(rdriData[a].time), "yyyy-MM-dd  HH:mm"));
            temp.push(rdriData[a].rate1);
            temp.push(rdriData[a].elbow2);
            temp.push(rdriData[a].rate2);
            temp.push(rdriData[a].elbow3);
            temp.push(rdriData[a].rate3);
            rdriTableData.push(temp);
          }
          if (new Date(rdriData[a].time) < startDaycheck) {
            preDayCheck = true;
          }
        }
        setRdri(rdriTableData);

        preDayCheck = false;

        const ruriData = await getRuri(startDate, endDate);
        console.log(ruriData);

        const ruriTableData = [
          ["Date Time", "Rate1", "Elbow2", "Rate2", "Elbow3", "Rate3"],
        ];
        temp = [];
        for (let a = 0; a < ruriData.length; a++) {
          if (
            new Date(ruriData[a].time) <= endDaycheck &&
            preDayCheck === false
          ) {
            temp = [];
            temp.push(format(new Date(ruriData[a].time), "yyyy-MM-dd  HH:mm"));
            temp.push(ruriData[a].rate1);
            temp.push(ruriData[a].elbow2);
            temp.push(ruriData[a].rate2);
            temp.push(ruriData[a].elbow3);
            temp.push(ruriData[a].rate3);
            ruriTableData.push(temp);
          }
          if (new Date(ruriData[a].time) < startDaycheck) {
            preDayCheck = true;
          }
        }
        setRuri(ruriTableData);

        preDayCheck = false;
        const selData = await getSel(startDate, endDate);
        console.log(selData);
        const selTableData = [["Date Time", "Level"]];

        temp = [];
        for (let a = 0; a < selData.length; a++) {
          if (
            new Date(selData[a].time) <= endDaycheck &&
            preDayCheck === false
          ) {
            temp = [];
            temp.push(format(new Date(selData[a].time), "yyyy-MM-dd  HH:mm"));
            temp.push(selData[a].level);

            selTableData.push(temp);
          }
          if (new Date(selData[a].time) < startDaycheck) {
            preDayCheck = true;
          }
        }
        setSel(selTableData);

        preDayCheck = false;
        const ndzData = await getNdz(startDate, endDate);
        console.log(ndzData);

        const ndzTableData = [["Date Time", "Notice"]];
        temp = [];
        for (let a = 0; a < ndzData.length; a++) {
          if (
            new Date(ndzData[a].time) <= endDaycheck &&
            preDayCheck === false
          ) {
            temp = [];
            temp.push(format(new Date(ndzData[a].time), "yyyy-MM-dd  HH:mm"));
            temp.push(ndzData[a].notice);

            ndzTableData.push(temp);
          }
          if (new Date(ndzData[a].time) < startDaycheck) {
            preDayCheck = true;
          }
        }
        setNdz(ndzTableData);

        preDayCheck = false;
        const mztData = await getMzt(startDate, endDate);
        console.log(mztData);
        const mztTableData = [["Date Time", "Period"]];
        temp = [];
        for (let a = 0; a < mztData.length; a++) {
          if (
            new Date(mztData[a].time) <= endDaycheck &&
            preDayCheck === false
          ) {
            temp = [];
            temp.push(format(new Date(mztData[a].time), "yyyy-MM-dd  HH:mm"));
            temp.push(mztData[a].periodMin);

            mztTableData.push(temp);
          }
          if (new Date(mztData[a].time) < startDaycheck) {
            preDayCheck = true;
          }
        }
        setMzt(mztTableData);

        preDayCheck = false;
        const mnztData = await getMnzt(startDate, endDate);
        console.log(mnztData);
        const mnztTableData = [["Date Time", "Period"]];
        temp = [];
        for (let a = 0; a < mnztData.length; a++) {
          if (
            new Date(mnztData[a].time) <= endDaycheck &&
            preDayCheck === false
          ) {
            temp = [];
            temp.push(format(new Date(mnztData[a].time), "yyyy-MM-dd  HH:mm"));
            temp.push(mnztData[a].periodMin);

            mnztTableData.push(temp);
          }
          if (new Date(mnztData[a].time) < startDaycheck) {
            preDayCheck = true;
          }
        }
        setMnzt(mnztTableData);

        preDayCheck = false;
        const silData = await getSil(startDate, endDate);
        console.log(silData);
        const silTableData = [["Date Time", "Level"]];
        temp = [];
        for (let a = 0; a < silData.length; a++) {
          if (
            new Date(silData[a].time) <= endDaycheck &&
            preDayCheck === false
          ) {
            temp = [];
            temp.push(format(new Date(silData[a].time), "yyyy-MM-dd  HH:mm"));
            temp.push(silData[a].level);

            silTableData.push(temp);
          }
          if (new Date(silData[a].time) < startDaycheck) {
            preDayCheck = true;
          }
        }
        setSil(silTableData);

        preDayCheck = false;
        const ntoData = await getNto(startDate, endDate);
        console.log(ntoData);
        const ntoTableData = [["Date Time", "Notice"]];
        temp = [];
        for (let a = 0; a < ntoData.length; a++) {
          if (
            new Date(ntoData[a].time) <= endDaycheck &&
            preDayCheck === false
          ) {
            temp = [];
            temp.push(format(new Date(ntoData[a].time), "yyyy-MM-dd  HH:mm"));
            temp.push(ntoData[a].notice);

            ntoTableData.push(temp);
          }
          if (new Date(ntoData[a].time) < startDaycheck) {
            preDayCheck = true;
          }
        }
        setNto(ntoTableData);

        preDayCheck = false;
        const ntbData = await getNtb(startDate, endDate);
        console.log(ntbData);
        const ntbTableData = [["Date Time", "Notice"]];
        temp = [];
        for (let a = 0; a < ntbData.length; a++) {
          if (
            new Date(ntbData[a].time) <= endDaycheck &&
            preDayCheck === false
          ) {
            temp = [];
            temp.push(format(new Date(ntbData[a].time), "yyyy-MM-dd  HH:mm"));
            temp.push(ntbData[a].notice);

            ntbTableData.push(temp);
          }
          if (new Date(ntbData[a].time) < startDaycheck) {
            preDayCheck = true;
          }
        }
        setNtb(ntbTableData);

        // let mdvData;
        // preDayCheck = false;
        // mdvData = await getMdv(startDate, endDate);
        // console.log(mdvData);
        // const mdvTableData = [["Date Time", "Volume"]];
        // temp = [];
        // for (let a = 0; a < mdvData.length; a++) {
        //   if (
        //     new Date(mdvData[a].time) <= endDaycheck &&
        //     preDayCheck === false
        //   ) {
        //     temp = [];
        //     temp.push(format(new Date(mdvData[a].time), "yyyy-MM-dd  hh:mm"));
        //     temp.push(mdvData[a].volumeMax);

        //     mdvTableData.push(temp);
        //   }
        //   if (new Date(mdvData[a].time) < startDaycheck) {
        //     preDayCheck = true;
        //   }
        // }
        // setMdv(mdvTableData);

        // let mdpData;
        // preDayCheck = false;
        // mdpData = await getMdp(startDate, endDate);
        // console.log(mdpData);
        // const mdpTableData = [["Date Time", "Volume"]];
        // temp = [];
        // for (let a = 0; a < mdpData.length; a++) {
        //   if (
        //     new Date(mdpData[a].time) <= endDaycheck &&
        //     preDayCheck === false
        //   ) {
        //     temp = [];
        //     temp.push(format(new Date(mdpData[a].time), "yyyy-MM-dd  hh:mm"));
        //     temp.push(mdpData[a].volumeMax);

        //     mdpTableData.push(temp);
        //   }
        //   if (new Date(mdpData[a].time) < startDaycheck) {
        //     preDayCheck = true;
        //   }
        // }
        // setMdp(mdpTableData);
      }
      setDataShow(false);
    };

    getData();
  }, [bmuName]);

  const options = {
    allowHtml: true,
    showRowNumber: false,
  };

  const formatters = [
    {
      column: 1,
      options: {
        prefix: "$",
        negativeColor: "red",
        negativeParens: true,
      },
    },
  ];

  const customStyles = {
    content: {
      top: "15%",
      left: "20%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      //transform: "translate(-50%, -50%)",
    },
  };

  const titleStyle = { fontSize: "12px" };
  const divWidth = "430px";
  const divWidth2 = "215px";
  const divHeight = "135px";
  return (
    <>
      <Modal isOpen={dynamicModalShow} style={customStyles}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <b>{bmuName}</b>
            </div>
            <div className="modal-body">
              {dataShow ? (
                <>
                  <div style={{ textAlign: "center", width: "840px" }}>
                    <ClipLoader loading={dataShow} />{" "}
                  </div>
                </>
              ) : (
                <div className="container">
                  <div className="row">
                    <div
                      style={{
                        height: divHeight,
                        width: divWidth,
                        border: "1px solid #DCDCDC",
                        float: "left",
                      }}
                    >
                      <b className="py-0 my-0" style={titleStyle}>
                        RURE- Run up Rate Export{" "}
                      </b>
                      <div style={{ height: "100px" }}>
                        <Chart
                          chartType="Table"
                          width="100%"
                          height="100%"
                          data={rure}
                          options={options}
                          formatters={formatters}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        height: divHeight,
                        width: divWidth,
                        border: "1px solid #DCDCDC",
                        float: "left",
                      }}
                    >
                      <b className="py-0 my-0" style={titleStyle}>
                        RDRE - Run Down Rate Export
                      </b>
                      <div style={{ height: "100px" }}>
                        <Chart
                          chartType="Table"
                          width="100%"
                          height="100%"
                          data={rdre}
                          options={options}
                          formatters={formatters}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      style={{
                        height: divHeight,
                        width: divWidth,
                        border: "1px solid #DCDCDC",
                        float: "left",
                      }}
                    >
                      <b className="py-0 my-0" style={titleStyle}>
                        RURI- Run up Rate Import{" "}
                      </b>
                      <div style={{ height: "100px" }}>
                        <Chart
                          chartType="Table"
                          width="100%"
                          height="100%"
                          data={ruri}
                          options={options}
                          formatters={formatters}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        height: divHeight,
                        width: divWidth,
                        border: "1px solid #DCDCDC",
                        float: "left",
                      }}
                    >
                      <b className="py-0 my-0" style={titleStyle}>
                        RDRI - Run Down Rate Import
                      </b>
                      <div style={{ height: "100px" }}>
                        <Chart
                          chartType="Table"
                          width="100%"
                          height="100%"
                          data={rdri}
                          options={options}
                          formatters={formatters}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      style={{
                        height: divHeight,
                        width: divWidth2,
                        border: "1px solid #DCDCDC",
                        float: "left",
                      }}
                    >
                      <b className="py-0 my-0" style={titleStyle}>
                        SEL- Stable Export Limit
                      </b>
                      <div style={{ height: "100px" }}>
                        <Chart
                          chartType="Table"
                          width="100%"
                          height="100%"
                          data={sel}
                          options={options}
                          formatters={formatters}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        height: divHeight,
                        width: divWidth2,
                        border: "1px solid #DCDCDC",
                        float: "left",
                      }}
                    >
                      <b className="py-0 my-0" style={titleStyle}>
                        SIL- Stable Import Limit
                      </b>
                      <div style={{ height: "100px" }}>
                        <Chart
                          chartType="Table"
                          width="100%"
                          height="100%"
                          data={sil}
                          options={options}
                          formatters={formatters}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        height: divHeight,
                        width: divWidth2,
                        border: "1px solid #DCDCDC",
                        float: "left",
                      }}
                    >
                      <b className="py-0 my-0" style={titleStyle}>
                        MZT - Min Zero Time
                      </b>
                      <div style={{ height: "100px" }}>
                        <Chart
                          chartType="Table"
                          width="100%"
                          height="100%"
                          data={mzt}
                          options={options}
                          formatters={formatters}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        height: divHeight,
                        width: divWidth2,
                        border: "1px solid #DCDCDC",
                        float: "left",
                      }}
                    >
                      <b className="py-0 my-0" style={titleStyle}>
                        MNZT - Min Non Zero Time
                      </b>
                      <div style={{ height: "100px" }}>
                        <Chart
                          chartType="Table"
                          width="100%"
                          height="100%"
                          data={mnzt}
                          options={options}
                          formatters={formatters}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      style={{
                        height: divHeight,
                        width: divWidth2,
                        border: "1px solid #DCDCDC",
                        float: "left",
                      }}
                    >
                      <b className="py-0 my-0" style={titleStyle}>
                        NDZ- Notice to Deviate Zero
                      </b>
                      <div style={{ height: "100px" }}>
                        <Chart
                          chartType="Table"
                          width="100%"
                          height="100%"
                          data={ndz}
                          options={options}
                          formatters={formatters}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        height: divHeight,
                        width: divWidth2,
                        border: "1px solid #DCDCDC",
                        float: "left",
                      }}
                    >
                      <b className="py-0 my-0" style={titleStyle}>
                        NTO- Notice to Deviate Offers
                      </b>
                      <div style={{ height: "100px" }}>
                        <Chart
                          chartType="Table"
                          width="100%"
                          height="100%"
                          data={nto}
                          options={options}
                          formatters={formatters}
                        />
                      </div>
                    </div>

                    <div
                      style={{
                        height: divHeight,
                        width: divWidth2,
                        border: "1px solid #DCDCDC",
                        float: "left",
                      }}
                    >
                      <b className="py-0 my-0" style={titleStyle}>
                        NTB- Notice to Deviate Bids
                      </b>
                      <div style={{ height: "100px" }}>
                        <Chart
                          chartType="Table"
                          width="100%"
                          height="100%"
                          data={ntb}
                          options={options}
                          formatters={formatters}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="modal-footer">
              <button className="btn btn-primary" onClick={closeDynamicModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DynamicDataModal;
