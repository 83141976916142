import React from "react";
import {
  addDays,
  addHours,
  subMinutes,
  format,
  getDate,
  getMonth,
} from "date-fns";
import { getTimezoneOffset } from "date-fns-tz";

const acceptance2Helper = {
  acceptance: async function (bmu, startDate) {
    const endDate = format(new Date(addDays(startDate, 1)), "yyyy-MM-dd");
    let tzDiff = getTimezoneOffset("Europe/Dublin", startDate) / 1000 / 60 / 60;
    let newStartDate;
    let newEndDate;
    if (tzDiff === 1) {
      newStartDate =
        format(new Date(addDays(startDate, -1)), "yyyy-MM-dd") + "T23%3A30z";
      newEndDate = startDate + "T22%3A30z";
    } else {
      newStartDate = startDate + "T00%3A30z";
      newEndDate = endDate + "T23%3A30z";
    }
    //console.log(tzDiff);
    //console.log(newStartDate);
    let bidData = [];
    let bidVolData = [];
    let bidCashData = [];
    let offerVolData = [];
    let offerCashData = [];
    let bidoff;

    const getBidOffer = async (a, b, c) => {
      const bidofferResponse = await fetch(
        //`https://data.elexon.co.uk/bmrs/api/v1/balancing/bid-offer?bmUnit=T_DRAXX-1&from=2024-09-08T00%3A00z&to=2024-09-09T00%3A00z&format=json`
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/bid-offer?bmUnit=${a}&from=${b}&to=${c}&format=json`
      );
      bidoff = await bidofferResponse.json();
    };
    const getBidVol = async (a, b) => {
      //console.log("Start bidVol");
      const bidVolResponse = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/indicative/volumes/all/bid/${a}?bmUnit=${b}&format=json`
        //`https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/acceptance/volumes/all/bid/${a}?bmUnit=${b}&format=json`
      );

      const FullbidVol = await bidVolResponse.json();
      console.log(FullbidVol.data);

      const group = (fn) => (xs) =>
        Object.values(
          xs.reduce(
            (a, x, _, __, k = fn(x)) => ((a[k] = [...(a[k] || []), x]), a),
            {}
          )
        );

      const sum = (ns) => ns.reduce((a, b) => a + b, 0);

      const combine = (xs) =>
        group((x) => x.settlementPeriod)(xs).map((xs) => ({
          settlementPeriod: xs[0].settlementPeriod,
          startTime: xs[0].startTime,
          negative1: xs.map((x) => x.pairVolumes.negative1),
          negative2: xs.map((x) => x.pairVolumes.negative2),
          negative3: xs.map((x) => x.pairVolumes.negative3),
          negative4: xs.map((x) => x.pairVolumes.negative4),
          negative5: xs.map((x) => x.pairVolumes.negative5),
          negative6: xs.map((x) => x.pairVolumes.negative6),
          positive1: xs.map((x) => x.pairVolumes.positive1),
          positive2: xs.map((x) => x.pairVolumes.positive2),
          positive3: xs.map((x) => x.pairVolumes.positive3),
          positive4: xs.map((x) => x.pairVolumes.positive4),
          positive5: xs.map((x) => x.pairVolumes.positive5),
          positive6: xs.map((x) => x.pairVolumes.positive6),
        }));

      const bidVol = combine(FullbidVol.data).sort(
        (a, b) => b.settlementPeriod - a.settlementPeriod
      );
      console.log(bidVol);
      if (bidVol.length > 0) {
        console.log(
          Object.values(bidVol[0].negative1).reduce((a, b) => (a > b ? b : a))
        );
      }

      let temp = [];
      for (let a = 0; a < bidVol.length; a++) {
        temp = [];
        temp.push(bidVol[a].settlementPeriod);
        temp.push(-1);
        temp.push(
          Object.values(bidVol[a].negative1).reduce((a, b) => (a > b ? b : a))
        );
        temp.push(
          Object.values(bidVol[a].positive1).reduce((a, b) => (a > b ? b : a))
        );
        bidVolData.push(temp);

        if (
          Object.values(bidVol[a].negative2).reduce((a, b) =>
            a > b ? b : a
          ) !== null
        ) {
          let temp = [];
          temp.push(bidVol[a].settlementPeriod);
          temp.push(-2);
          temp.push(
            Object.values(bidVol[a].negative2).reduce((a, b) => (a > b ? b : a))
          );
          temp.push(
            Object.values(bidVol[a].positive2).reduce((a, b) => (a > b ? b : a))
          );
          bidVolData.push(temp);
        }
        if (
          Object.values(bidVol[a].negative3).reduce((a, b) =>
            a > b ? b : a
          ) !== null
        ) {
          let temp = [];
          temp.push(bidVol[a].settlementPeriod);
          temp.push(-3);
          temp.push(
            Object.values(bidVol[a].negative3).reduce((a, b) => (a > b ? b : a))
          );
          temp.push(
            Object.values(bidVol[a].positive3).reduce((a, b) => (a > b ? b : a))
          );
          bidVolData.push(temp);
        }
        if (
          Object.values(bidVol[a].negative4).reduce((a, b) =>
            a > b ? b : a
          ) !== null
        ) {
          let temp = [];
          temp.push(bidVol[a].settlementPeriod);
          temp.push(-4);
          temp.push(
            Object.values(bidVol[a].negative4).reduce((a, b) => (a > b ? b : a))
          );
          temp.push(
            Object.values(bidVol[a].positive4).reduce((a, b) => (a > b ? b : a))
          );
          bidVolData.push(temp);
        }
        if (
          Object.values(bidVol[a].negative5).reduce((a, b) =>
            a > b ? b : a
          ) !== null
        ) {
          let temp = [];
          temp.push(bidVol[a].settlementPeriod);
          temp.push(-5);
          temp.push(
            Object.values(bidVol[a].negative5).reduce((a, b) => (a > b ? b : a))
          );
          temp.push(
            Object.values(bidVol[a].positive5).reduce((a, b) => (a > b ? b : a))
          );
          bidVolData.push(temp);
        }
        if (
          Object.values(bidVol[a].negative6).reduce((a, b) =>
            a > b ? b : a
          ) !== null
        ) {
          let temp = [];
          temp.push(bidVol[a].settlementPeriod);
          temp.push(-6);
          temp.push(
            Object.values(bidVol[a].negative6).reduce((a, b) => (a > b ? b : a))
          );
          temp.push(
            Object.values(bidVol[a].positive6).reduce((a, b) => (a > b ? b : a))
          );
          bidVolData.push(temp);
        }
      }
    };

    const getBidCash = async (a, b) => {
      const bidCashResponse = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/indicative/cashflows/all/bid/${a}?bmUnit=${b}&format=json`
      );
      const unsortedBidCash = await bidCashResponse.json();
      const bidCash = unsortedBidCash.data.sort(
        (a, b) => new Date(b.startTime) - new Date(a.startTime)
      );
      console.log(bidCash);
      bidCashData = [];
      for (let a = 0; a < bidCash.length; a++) {
        let temp = [];
        temp.push(bidCash[a].settlementPeriod);
        temp.push(-1);
        temp.push(bidCash[a].bidOfferPairCashflows.negative1);
        temp.push(bidCash[a].bidOfferPairCashflows.positive1);
        bidCashData.push(temp);
        if (bidCash[a].bidOfferPairCashflows.negative2 !== null) {
          let temp = [];
          temp.push(bidCash[a].settlementPeriod);
          temp.push(-2);
          temp.push(bidCash[a].bidOfferPairCashflows.negative2);
          temp.push(bidCash[a].bidOfferPairCashflows.positive2);
          bidCashData.push(temp);
        }
        if (bidCash[a].bidOfferPairCashflows.negative3 !== null) {
          let temp = [];
          temp.push(bidCash[a].settlementPeriod);
          temp.push(-3);
          temp.push(bidCash[a].bidOfferPairCashflows.negative3);
          temp.push(bidCash[a].bidOfferPairCashflows.positive3);
          bidCashData.push(temp);
        }
        if (bidCash[a].bidOfferPairCashflows.negative4 !== null) {
          let temp = [];
          temp.push(bidCash[a].settlementPeriod);
          temp.push(-4);
          temp.push(bidCash[a].bidOfferPairCashflows.negative4);
          temp.push(bidCash[a].bidOfferPairCashflows.positive4);
          bidCashData.push(temp);
        }
        if (bidCash[a].bidOfferPairCashflows.negative5 !== null) {
          let temp = [];
          temp.push(bidCash[a].settlementPeriod);
          temp.push(-5);
          temp.push(bidCash[a].bidOfferPairCashflows.negative5);
          temp.push(bidCash[a].bidOfferPairCashflows.positive5);
          bidCashData.push(temp);
        }
        if (bidCash[a].bidOfferPairCashflows.negative6 !== null) {
          let temp = [];
          temp.push(bidCash[a].settlementPeriod);
          temp.push(-6);
          temp.push(bidCash[a].bidOfferPairCashflows.negative6);
          temp.push(bidCash[a].bidOfferPairCashflows.positive6);
          bidCashData.push(temp);
        }
      }
      console.log(bidCashData);
    };
    const getOfferVol = async (a, b) => {
      //console.log("Start bidVol");
      const offerVolResponse = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/indicative/volumes/all/offer/${a}?bmUnit=${b}&format=json`
      );

      const FullofferVol = await offerVolResponse.json();
      console.log(FullofferVol);

      const group = (fn) => (xs) =>
        Object.values(
          xs.reduce(
            (a, x, _, __, k = fn(x)) => ((a[k] = [...(a[k] || []), x]), a),
            {}
          )
        );

      const sum = (ns) => ns.reduce((a, b) => a + b, 0);

      const combine = (xs) =>
        group((x) => x.settlementPeriod)(xs).map((xs) => ({
          settlementPeriod: xs[0].settlementPeriod,
          startTime: xs[0].startTime,
          negative1: xs.map((x) => x.pairVolumes.negative1),
          negative2: xs.map((x) => x.pairVolumes.negative2),
          negative3: xs.map((x) => x.pairVolumes.negative3),
          negative4: xs.map((x) => x.pairVolumes.negative4),
          negative5: xs.map((x) => x.pairVolumes.negative5),
          negative6: xs.map((x) => x.pairVolumes.negative6),
          positive1: xs.map((x) => x.pairVolumes.positive1),
          positive2: xs.map((x) => x.pairVolumes.positive2),
          positive3: xs.map((x) => x.pairVolumes.positive3),
          positive4: xs.map((x) => x.pairVolumes.positive4),
          positive5: xs.map((x) => x.pairVolumes.positive5),
          positive6: xs.map((x) => x.pairVolumes.positive6),
        }));

      const offerVol = combine(FullofferVol.data).sort(
        (a, b) => b.settlementPeriod - a.settlementPeriod
      );

      // const offerVol = FullofferVol.data.filter(
      //   (x) => x.dataType === "Original"
      // );
      console.log(offerVol);
      let temp = [];
      for (let a = 0; a < offerVol.length; a++) {
        // let periodOfferVol = FullofferVol.data.filter(
        //   (x) => x.dataType === "Original"
        // );
        // let maxNegative1 = periodOfferVol[a].pairVolumes.negative1;
        // let maxNegative2 = periodOfferVol[a].pairVolumes.negative2;
        // let maxNegative3 = periodOfferVol[a].pairVolumes.negative3;
        // let maxNegative4 = periodOfferVol[a].pairVolumes.negative4;
        // let maxNegative5 = periodOfferVol[a].pairVolumes.negative5;
        // let maxNegative6 = periodOfferVol[a].pairVolumes.negative6;
        // let maxPositive1 = periodOfferVol[a].pairVolumes.positive1;
        // let maxPositive2 = periodOfferVol[a].pairVolumes.positive2;
        // let maxPositive3 = periodOfferVol[a].pairVolumes.positive3;
        // let maxPositive4 = periodOfferVol[a].pairVolumes.positive4;
        // let maxPositive5 = periodOfferVol[a].pairVolumes.positive5;
        // let maxPositive6 = periodOfferVol[a].pairVolumes.positive6;
        // periodOfferVol = FullofferVol.data.filter(
        //   (x) => x.dataType === "Original-Priced"
        // );
        // if (periodOfferVol[a].pairVolumes.negative1 > maxNegative1) {
        //   maxNegative1 = periodOfferVol[a].pairVolumes.negative1;
        // }
        // if (periodOfferVol[a].pairVolumes.negative2 > maxNegative2) {
        //   maxNegative2 = periodOfferVol[a].pairVolumes.negative2;
        // }
        // if (periodOfferVol[a].pairVolumes.negative3 > maxNegative3) {
        //   maxNegative3 = periodOfferVol[a].pairVolumes.negative3;
        // }
        // if (periodOfferVol[a].pairVolumes.negative4 > maxNegative4) {
        //   maxNegative4 = periodOfferVol[a].pairVolumes.negative4;
        // }
        // if (periodOfferVol[a].pairVolumes.negative5 > maxNegative5) {
        //   maxNegative5 = periodOfferVol[a].pairVolumes.negative5;
        // }
        // if (periodOfferVol[a].pairVolumes.negative6 > maxNegative6) {
        //   maxNegative6 = periodOfferVol[a].pairVolumes.negative6;
        // }
        // if (periodOfferVol[a].pairVolumes.positive1 > maxPositive1) {
        //   maxPositive1 = periodOfferVol[a].pairVolumes.positive1;
        // }
        // if (periodOfferVol[a].pairVolumes.positive2 > maxPositive2) {
        //   maxPositive2 = periodOfferVol[a].pairVolumes.positive2;
        // }
        // if (periodOfferVol[a].pairVolumes.positive3 > maxPositive3) {
        //   maxPositive3 = periodOfferVol[a].pairVolumes.positive3;
        // }
        // if (periodOfferVol[a].pairVolumes.positive4 > maxPositive4) {
        //   maxPositive4 = periodOfferVol[a].pairVolumes.positive4;
        // }
        // if (periodOfferVol[a].pairVolumes.positive5 > maxPositive5) {
        //   maxPositive5 = periodOfferVol[a].pairVolumes.positive5;
        // }
        // if (periodOfferVol[a].pairVolumes.positive6 > maxPositive6) {
        //   maxPositive6 = periodOfferVol[a].pairVolumes.positive6;
        // }

        // periodOfferVol = FullofferVol.data.filter(
        //   (x) => x.dataType === "Re-priced"
        // );
        // if (periodOfferVol[a].pairVolumes.negative1 > maxNegative1) {
        //   maxNegative1 = periodOfferVol[a].pairVolumes.negative1;
        // }
        // if (periodOfferVol[a].pairVolumes.negative2 > maxNegative2) {
        //   maxNegative2 = periodOfferVol[a].pairVolumes.negative2;
        // }
        // if (periodOfferVol[a].pairVolumes.negative3 > maxNegative3) {
        //   maxNegative3 = periodOfferVol[a].pairVolumes.negative3;
        // }
        // if (periodOfferVol[a].pairVolumes.negative4 > maxNegative4) {
        //   maxNegative4 = periodOfferVol[a].pairVolumes.negative4;
        // }
        // if (periodOfferVol[a].pairVolumes.negative5 > maxNegative5) {
        //   maxNegative5 = periodOfferVol[a].pairVolumes.negative5;
        // }
        // if (periodOfferVol[a].pairVolumes.negative6 > maxNegative6) {
        //   maxNegative6 = periodOfferVol[a].pairVolumes.negative6;
        // }
        // if (periodOfferVol[a].pairVolumes.positive1 > maxPositive1) {
        //   maxPositive1 = periodOfferVol[a].pairVolumes.positive1;
        // }
        // if (periodOfferVol[a].pairVolumes.positive2 > maxPositive2) {
        //   maxPositive2 = periodOfferVol[a].pairVolumes.positive2;
        // }
        // if (periodOfferVol[a].pairVolumes.positive3 > maxPositive3) {
        //   maxPositive3 = periodOfferVol[a].pairVolumes.positive3;
        // }
        // if (periodOfferVol[a].pairVolumes.positive4 > maxPositive4) {
        //   maxPositive4 = periodOfferVol[a].pairVolumes.positive4;
        // }
        // if (periodOfferVol[a].pairVolumes.positive5 > maxPositive5) {
        //   maxPositive5 = periodOfferVol[a].pairVolumes.positive5;
        // }
        // if (periodOfferVol[a].pairVolumes.positive6 > maxPositive6) {
        //   maxPositive6 = periodOfferVol[a].pairVolumes.positive6;
        // }
        // periodOfferVol = FullofferVol.data.filter(
        //   (x) => x.dataType === "Tagged"
        // );
        // if (periodOfferVol[a].pairVolumes.negative1 > maxNegative1) {
        //   maxNegative1 = periodOfferVol[a].pairVolumes.negative1;
        // }
        // if (periodOfferVol[a].pairVolumes.negative2 > maxNegative2) {
        //   maxNegative2 = periodOfferVol[a].pairVolumes.negative2;
        // }
        // if (periodOfferVol[a].pairVolumes.negative3 > maxNegative3) {
        //   maxNegative3 = periodOfferVol[a].pairVolumes.negative3;
        // }
        // if (periodOfferVol[a].pairVolumes.negative4 > maxNegative4) {
        //   maxNegative4 = periodOfferVol[a].pairVolumes.negative4;
        // }
        // if (periodOfferVol[a].pairVolumes.negative5 > maxNegative5) {
        //   maxNegative5 = periodOfferVol[a].pairVolumes.negative5;
        // }
        // if (periodOfferVol[a].pairVolumes.negative6 > maxNegative6) {
        //   maxNegative6 = periodOfferVol[a].pairVolumes.negative6;
        // }
        // if (periodOfferVol[a].pairVolumes.positive1 > maxPositive1) {
        //   maxPositive1 = periodOfferVol[a].pairVolumes.positive1;
        // }
        // if (periodOfferVol[a].pairVolumes.positive2 > maxPositive2) {
        //   maxPositive2 = periodOfferVol[a].pairVolumes.positive2;
        // }
        // if (periodOfferVol[a].pairVolumes.positive3 > maxPositive3) {
        //   maxPositive3 = periodOfferVol[a].pairVolumes.positive3;
        // }
        // if (periodOfferVol[a].pairVolumes.positive4 > maxPositive4) {
        //   maxPositive4 = periodOfferVol[a].pairVolumes.positive4;
        // }
        // if (periodOfferVol[a].pairVolumes.positive5 > maxPositive5) {
        //   maxPositive5 = periodOfferVol[a].pairVolumes.positive5;
        // }
        // if (periodOfferVol[a].pairVolumes.positive6 > maxPositive6) {
        //   maxPositive6 = periodOfferVol[a].pairVolumes.positive6;
        // }
        // console.log(maxPositive1);
        temp = [];
        temp.push(offerVol[a].settlementPeriod);
        temp.push(1);
        temp.push(
          Object.values(offerVol[a].negative1).reduce((a, b) => (a > b ? a : b))
        );
        temp.push(
          Object.values(offerVol[a].positive1).reduce((a, b) => (a > b ? a : b))
        );
        offerVolData.push(temp);
        if (
          Object.values(offerVol[a].negative2).reduce((a, b) =>
            a > b ? a : b
          ) !== null
        ) {
          let temp = [];
          temp.push(offerVol[a].settlementPeriod);
          temp.push(2);
          temp.push(
            Object.values(offerVol[a].negative2).reduce((a, b) =>
              a > b ? a : b
            )
          );
          temp.push(
            Object.values(offerVol[a].positive2).reduce((a, b) =>
              a > b ? a : b
            )
          );
          offerVolData.push(temp);
        }
        if (
          Object.values(offerVol[a].negative3).reduce((a, b) =>
            a > b ? a : b
          ) !== null
        ) {
          let temp = [];
          temp.push(offerVol[a].settlementPeriod);
          temp.push(3);
          temp.push(
            Object.values(offerVol[a].negative3).reduce((a, b) =>
              a > b ? a : b
            )
          );
          temp.push(
            Object.values(offerVol[a].positive3).reduce((a, b) =>
              a > b ? a : b
            )
          );
          offerVolData.push(temp);
        }
        if (
          Object.values(offerVol[a].negative4).reduce((a, b) =>
            a > b ? a : b
          ) !== null
        ) {
          let temp = [];
          temp.push(offerVol[a].settlementPeriod);
          temp.push(4);
          temp.push(
            Object.values(offerVol[a].negative4).reduce((a, b) =>
              a > b ? a : b
            )
          );
          temp.push(
            Object.values(offerVol[a].positive4).reduce((a, b) =>
              a > b ? a : b
            )
          );
          offerVolData.push(temp);
        }
        if (
          Object.values(offerVol[a].negative5).reduce((a, b) =>
            a > b ? a : b
          ) !== null
        ) {
          let temp = [];
          temp.push(offerVol[a].settlementPeriod);
          temp.push(5);
          temp.push(
            Object.values(offerVol[a].negative5).reduce((a, b) =>
              a > b ? a : b
            )
          );
          temp.push(
            Object.values(offerVol[a].positive5).reduce((a, b) =>
              a > b ? a : b
            )
          );
          offerVolData.push(temp);
        }
        if (
          Object.values(offerVol[a].negative6).reduce((a, b) =>
            a > b ? a : b
          ) !== null
        ) {
          let temp = [];
          temp.push(offerVol[a].settlementPeriod);
          temp.push(6);
          temp.push(
            Object.values(offerVol[a].negative6).reduce((a, b) =>
              a > b ? a : b
            )
          );
          temp.push(
            Object.values(offerVol[a].positive6).reduce((a, b) =>
              a > b ? a : b
            )
          );
          offerVolData.push(temp);
        }
      }
    };

    const getOfferCash = async (a, b) => {
      const offerCashResponse = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/indicative/cashflows/all/offer/${a}?bmUnit=${b}&format=json`
      );
      const unsortedOfferCash = await offerCashResponse.json();
      const offerCash = unsortedOfferCash.data.sort(
        (a, b) => b.settlementPeriod - a.settlementPeriod
      );
      //console.log(offerCash);
      for (let a = 0; a < offerCash.length; a++) {
        let temp = [];
        temp.push(offerCash[a].settlementPeriod);
        temp.push(1);
        temp.push(offerCash[a].bidOfferPairCashflows.negative1);
        temp.push(offerCash[a].bidOfferPairCashflows.positive1);
        offerCashData.push(temp);
        if (offerCash[a].bidOfferPairCashflows.negative2 !== null) {
          let temp = [];
          temp.push(offerCash[a].settlementPeriod);
          temp.push(2);
          temp.push(offerCash[a].bidOfferPairCashflows.negative2);
          temp.push(offerCash[a].bidOfferPairCashflows.positive2);
          offerCashData.push(temp);
        }
        if (offerCash[a].bidOfferPairCashflows.negative3 !== null) {
          let temp = [];
          temp.push(offerCash[a].settlementPeriod);
          temp.push(3);
          temp.push(offerCash[a].bidOfferPairCashflows.negative3);
          temp.push(offerCash[a].bidOfferPairCashflows.positive3);
          offerCashData.push(temp);
        }
        if (offerCash[a].bidOfferPairCashflows.negative4 !== null) {
          let temp = [];
          temp.push(offerCash[a].settlementPeriod);
          temp.push(4);
          temp.push(offerCash[a].bidOfferPairCashflows.negative4);
          temp.push(offerCash[a].bidOfferPairCashflows.positive4);
          offerCashData.push(temp);
        }
        if (offerCash[a].bidOfferPairCashflows.negative5 !== null) {
          let temp = [];
          temp.push(offerCash[a].settlementPeriod);
          temp.push(5);
          temp.push(offerCash[a].bidOfferPairCashflows.negative5);
          temp.push(offerCash[a].bidOfferPairCashflows.positive5);
          offerCashData.push(temp);
        }
        if (offerCash[a].bidOfferPairCashflows.negative6 !== null) {
          let temp = [];
          temp.push(offerCash[a].settlementPeriod);
          temp.push(6);
          temp.push(offerCash[a].bidOfferPairCashflows.negative6);
          temp.push(offerCash[a].bidOfferPairCashflows.positive6);
          offerCashData.push(temp);
        }
      }
    };
    let promises = [];

    promises.push(getBidVol(startDate, bmu));
    promises.push(getBidCash(startDate, bmu));
    promises.push(getOfferVol(startDate, bmu));
    promises.push(getOfferCash(startDate, bmu));
    promises.push(getBidOffer(bmu, newStartDate, newEndDate));
    await Promise.all(promises);
    console.log(bidVolData);
    console.log(bidCashData);
    console.log(offerVolData);
    console.log(offerCashData);
    console.log(bidoff);
    for (let i = 0; i < bidVolData.length; i++) {
      let temp = [];
      temp.push(bidVolData[i][0]);
      temp.push(bidVolData[i][1]);
      temp.push(bidVolData[i][2]);
      temp.push(bidCashData[i][2]);
      let bidoffperiod = bidoff.data.filter(
        (x) =>
          x.settlementPeriod === bidVolData[i][0] &&
          x.pairId === bidVolData[i][1]
      );
      //console.log(bidoffperiod[0]?.offer);
      temp.push(bidoffperiod[0]?.bid);
      // if (bidVolData[i][2] !== 0) {
      //   temp.push(bidCashData[i][2] / bidVolData[i][2]);
      // } else {
      //   temp.push("-");
      // }
      temp.push(bidVolData[i][3]);
      temp.push(bidCashData[i][3]);
      // if (bidVolData[i][3] !== 0) {
      //   temp.push(bidCashData[i][3] / bidVolData[i][3]);
      // } else {
      //   temp.push("-");
      // }
      temp.push(bidoffperiod[0]?.offer);
      bidData.push(temp);
    }
    for (let i = 0; i < offerVolData.length; i++) {
      let temp = [];
      temp.push(offerVolData[i][0]);
      temp.push(offerVolData[i][1]);
      temp.push(offerVolData[i][2]);
      temp.push(offerCashData[i][2]);
      let bidoffperiod = bidoff.data.filter(
        (x) =>
          x.settlementPeriod === offerVolData[i][0] &&
          x.pairId === offerVolData[i][1]
      );
      temp.push(bidoffperiod[0]?.bid);
      temp.push(offerVolData[i][3]);
      temp.push(offerCashData[i][3]);
      // if (offerVolData[i][3] !== 0) {
      //   temp.push(offerCashData[i][3] / offerVolData[i][3]);
      // } else {
      //   temp.push("-");
      // }
      temp.push(bidoffperiod[0]?.offer);
      bidData.push(temp);
    }
    //console.log(bidData);
    const bidData2 = bidData.sort((a, b) => b[0] - a[0]);
    console.log(bidData2);
    return bidData2;
  },
};

export default acceptance2Helper;
