import React from "react";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import MainContext from "../context/MainContext";
import { addDays, format, getDate, getMonth } from "date-fns";
import { ClipLoader } from "react-spinners";
import { getTimezoneOffset } from "date-fns-tz";

const DerateMarginHorizon = () => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [data, setData] = useState([]);

  const startDate = format(new Date(datePicker), "yyyy-MM-dd");
  const endDate = format(new Date(addDays(startDate, 2)), "yyyy-MM-dd");
  let tzDiff = getTimezoneOffset("Europe/Dublin", startDate) / 1000 / 60 / 60;
  let newStartDate;
  let newEndDate;
  if (tzDiff === 1) {
    newStartDate =
      format(new Date(addDays(startDate, -1)), "yyyy-MM-dd") + "T23%3A00z";
    newEndDate = endDate + "T22%3A00z";
  } else {
    newStartDate = startDate + "T00%3A00z";
    newEndDate = endDate + "T23%3A00z";
  }

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/forecast/system/loss-of-load?from=${newStartDate}&to=${newEndDate}&format=json`
      );
      const data = await response.json();
      //console.log(data);

      // let chartData = [
      //   [
      //     { type: "string", label: "Date Time" },
      //     { type: "number", label: "1hr Horizon Derate Margin" },
      //     { type: "number", label: "1hr Horizon LOLPDM" },
      //     { type: "number", label: "2hr Horizon Derate Margin" },
      //     { type: "number", label: "2hr Horizon LOLPDM" },
      //     { type: "number", label: "4hr Horizon Derate Margin" },
      //     { type: "number", label: "4hr Horizon LOLPDM" },
      //     { type: "number", label: "8hr Horizon Derate Margin" },
      //     { type: "number", label: "8hr Horizon LOLPDM" },
      //     { type: "number", label: "12hr Horizon Derate Margin" },
      //     { type: "number", label: "12hr Horizon LOLPDM" },
      //   ],
      // ];
      let chartData = [
        [
          { type: "string", label: "Date Time" },
          { type: "number", label: "1hr Horizon Derate Margin" },
          { type: "number", label: "2hr Horizon Derate Margin" },
          { type: "number", label: "4hr Horizon Derate Margin" },
          { type: "number", label: "8hr Horizon Derate Margin" },
          { type: "number", label: "12hr Horizon Derate Margin" },
          { type: "number", label: "1hr Horizon LOLPDM" },
          { type: "number", label: "2hr Horizon LOLPDM" },
          { type: "number", label: "4hr Horizon LOLPDM" },
          { type: "number", label: "8hr Horizon LOLPDM" },
          { type: "number", label: "12hr Horizon LOLPDM" },
        ],
      ];
      let period;
      let dateTimeStr;
      let temp = [];

      const uniqueArray = [];
      data.data.forEach((item) => {
        if (!uniqueArray.includes(item.startTime)) {
          uniqueArray.push(item.startTime);
        }
      });
      //console.log(uniqueArray);
      for (let i = 0; i < uniqueArray.length; i++) {
        temp = [];
        period = data.data.filter((x) => x.startTime === uniqueArray[i]);

        temp.push(format(new Date(period[0].startTime), "yyyy-MM-dd HH:mm"));

        period = data.data.filter(
          (x) => x.startTime === uniqueArray[i] && x.forecastHorizon === 1
        );
        temp.push(period[0]?.deratedMargin);
        //temp.push(period[0]?.lossOfLoadProbability);
        period = data.data.filter(
          (x) => x.startTime === uniqueArray[i] && x.forecastHorizon === 2
        );
        temp.push(period[0]?.deratedMargin);
        //temp.push(period[0]?.lossOfLoadProbability);
        period = data.data.filter(
          (x) => x.startTime === uniqueArray[i] && x.forecastHorizon === 4
        );
        temp.push(period[0]?.deratedMargin);
        //temp.push(period[0]?.lossOfLoadProbability);
        period = data.data.filter(
          (x) => x.startTime === uniqueArray[i] && x.forecastHorizon === 8
        );
        temp.push(period[0]?.deratedMargin);
        //temp.push(period[0]?.lossOfLoadProbability);
        period = data.data.filter(
          (x) => x.startTime === uniqueArray[i] && x.forecastHorizon === 12
        );
        temp.push(period[0]?.deratedMargin);
        //temp.push(period[0]?.lossOfLoadProbability);

        period = data.data.filter(
          (x) => x.startTime === uniqueArray[i] && x.forecastHorizon === 1
        );
        //temp.push(period[0]?.deratedMargin);
        temp.push(period[0]?.lossOfLoadProbability);
        period = data.data.filter(
          (x) => x.startTime === uniqueArray[i] && x.forecastHorizon === 2
        );
        //temp.push(period[0]?.deratedMargin);
        temp.push(period[0]?.lossOfLoadProbability);
        period = data.data.filter(
          (x) => x.startTime === uniqueArray[i] && x.forecastHorizon === 4
        );
        //temp.push(period[0]?.deratedMargin);
        temp.push(period[0]?.lossOfLoadProbability);
        period = data.data.filter(
          (x) => x.startTime === uniqueArray[i] && x.forecastHorizon === 8
        );
        //temp.push(period[0]?.deratedMargin);
        temp.push(period[0]?.lossOfLoadProbability);
        period = data.data.filter(
          (x) => x.startTime === uniqueArray[i] && x.forecastHorizon === 12
        );
        //temp.push(period[0]?.deratedMargin);
        temp.push(period[0]?.lossOfLoadProbability);

        chartData.push(temp);
      }
      setData(chartData);
      setLoading(false);
    };
    getData();
  }, [datePicker]);

  const options = {
    title: "Derate Forecast Horizon ",
    legend: { position: "top", maxLines: 2, textStyle: { fontSize: 14 } },
    focusTarget: "category",

    height: "100%",
    width: "100%",
    // series: {
    //   0: { lineWidth: 2, pointSize: 2.1, color: "#3366cc" },
    //   1: { targetAxisIndex: 1, type: "bars", color: "#3366cc" },
    //   2: { lineWidth: 2, pointSize: 2.1, color: "#dc3912" },
    //   3: { targetAxisIndex: 1, type: "bars", color: "#dc3912" },
    //   4: { lineWidth: 2, pointSize: 2.1, color: "#ff9900" },
    //   5: { targetAxisIndex: 1, type: "bars", color: "#ff9900" },
    //   6: { lineWidth: 2, pointSize: 2.1, color: "#0099c6" },
    //   7: { targetAxisIndex: 1, type: "bars", color: "#0099c6" },
    //   8: { lineWidth: 2, pointSize: 2.1, color: "#990099" },
    //   9: { targetAxisIndex: 1, type: "bars", color: "#990099" },
    // },

    series: {
      0: { lineWidth: 2, pointSize: 2.1, color: "#3366cc" },
      1: { lineWidth: 2, pointSize: 2.1, color: "#dc3912" },
      2: { lineWidth: 2, pointSize: 2.1, color: "#ff9900" },
      3: { lineWidth: 2, pointSize: 2.1, color: "#0099c6" },
      4: { lineWidth: 2, pointSize: 2.1, color: "#990099" },
      5: { targetAxisIndex: 1, type: "bars", color: "#3366cc" },
      6: { targetAxisIndex: 1, type: "bars", color: "#dc3912" },
      7: { targetAxisIndex: 1, type: "bars", color: "#ff9900" },
      8: { targetAxisIndex: 1, type: "bars", color: "#0099c6" },
      9: { targetAxisIndex: 1, type: "bars", color: "#990099" },
    },

    hAxis: {
      textStyle: { fontSize: 10 },
      title: "Date Time",
      //format: 'HH:mm',
      //minValue: new Date(y,m,d,0,0,0),
      // maxValue: new Date(y,m,d+1,0,0,0),
      //minValue: 1,
      //maxValue: 50,
      viewWindow: {
        //min: new Date(y,m,d,0,0,0),
        //max: new Date(y,m,d+1,0,0,0)
        //min: 1,
        //max: 50,
      },
    },
    vAxes: {
      0: { title: "Derate Margin MW" },
      1: { title: "LOLPDM" },
    },
    chartArea: { backgroundColor: "#fffff0" },
  };

  const setToolTip = async (e) => {
    //console.log({ data });
    const chartData = data;
    console.log(chartData[e.row]);
    console.log(e);
    //console.log(data[e.row][0]);
    const toolTip = document.getElementsByClassName(
      "google-visualization-tooltip"
    )[0];
    const content = <div className="custom-tooltip">test</div>;
    if (toolTip) {
      console.log(toolTip.innerHTML);
      toolTip.outerHTMLHTML = content;
    }
  };

  const chartEvents = [
    {
      eventName: "ready",
      callback: ({ chartWrapper, google }) => {
        const chart = chartWrapper.getChart();
        google.visualization.events.addListener(chart, "onmouseover", (e) => {
          const { row, column } = e;

          setToolTip(e);
          console.log(data[e.row + 1]);
          //console.log("MOUSE OVER ", { row, column });
        });
      },
    },
  ];

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader loading={loading} />{" "}
        </div>
      ) : (
        <>
          <div className="row ">
            <div
              className="d-flex col-md-12"
              style={{
                border: "1px solid #DCDCDC",
                height: "810px",
                width: "1750px",
                float: "none",
              }}
            >
              <Chart
                chartType="LineChart"
                options={options}
                data={data}
                //chartEvents={chartEvents}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DerateMarginHorizon;
