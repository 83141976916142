import React from "react";
import Modal from "react-modal";
import MainContext from "../context/MainContext";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { addDays, format, getDate, getMonth } from "date-fns";
import { ClipLoader } from "react-spinners";

Modal.setAppElement("#root");

const RemitModal = ({ remitModalShow, closeRemitModal, bmuName }) => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [tableData, setTableData] = useState([]);
  const [dataShow, setDataShow] = useState(false);

  useEffect(() => {
    console.log(bmuName);
    if (bmuName.length > 0) {
      const getData = async () => {
        setDataShow(true);
        const startDate = format(new Date(datePicker), "yyyy-MM-dd");
        const endDate = format(new Date(addDays(datePicker, 6)), "yyyy-MM-dd");

        const response = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/remit/list/by-event?from=${startDate}&to=${endDate}&assetId=${bmuName}&latestRevisionOnly=true&profileOnly=false&format=json
`
        );
        const events = await response.json();
        //   events.data.sort(
        //     (a, b) => new Date(a.publishTime) - new Date(b.publishTime)
        //   );
        console.log(events);

        const data = [
          [
            "Event Start",
            "Event End",
            "Availability",
            "Reason",
            "Messaage Status",
          ],
        ];
        let temp;

        for (let i = 0; i < events.data.length; i++) {
          const response2 = await fetch(
            `https://data.elexon.co.uk/bmrs/api/v1/remit/search?mrid=${events.data[i].mrid}&format=json`
          );
          const message = await response2.json();
          console.log(message);
          if (message.data[0].eventStatus !== "Dismissed") {
            temp = [];
            temp.push(
              format(
                new Date(message.data[0].eventStartTime),
                "yyyy-MM-dd HH:mm"
              )
            );
            temp.push(
              format(new Date(message.data[0].eventEndTime), "yyyy-MM-dd HH:mm")
            );
            temp.push(message.data[0].availableCapacity);
            temp.push(message.data[0].cause);
            temp.push(message.data[0].eventStatus);
            data.push(temp);
          }
        }
        data.sort((a, b) => new Date(a[0]) - new Date(b[0]));
        setTableData(data);
      };

      getData();
      setDataShow(false);
    }
  }, [bmuName]);

  const options = {
    allowHtml: true,
    showRowNumber: false,
  };

  const formatters = [
    {
      column: 1,
      options: {
        prefix: "$",
        negativeColor: "red",
        negativeParens: true,
      },
    },
  ];

  const customStyles = {
    content: {
      top: "15%",
      left: "20%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      //transform: "translate(-50%, -50%)",
    },
  };
  return (
    <Modal isOpen={remitModalShow} style={customStyles}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">{bmuName}</div>
          <div className="modal-body">
            {dataShow ? (
              <>
                <div style={{ textAlign: "center", width: "840px" }}>
                  <ClipLoader loading={dataShow} />{" "}
                </div>
              </>
            ) : (
              <>
                <Chart
                  chartType="Table"
                  width="100%"
                  height="100%"
                  data={tableData}
                  options={options}
                  formatters={formatters}
                />
              </>
            )}
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={closeRemitModal}>
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RemitModal;
