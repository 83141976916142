import React from "react";
import { useState, useEffect, useContext } from "react";
//import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import MainContext from "../context/MainContext";
import { addDays, format, getDate, getMonth } from "date-fns";
import { ClipLoader } from "react-spinners";
import { getTimezoneOffset } from "date-fns-tz";
import DetailDisbsad from "./DetailDisbsad";

const Disbsad = () => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [tableData, setTableData] = useState([]);
  const [detailModalShow, setDetailModalShow] = useState(false);
  const [sp, setSp] = useState(1);
  //const [options, setOptions] = useState([]);

  useEffect(() => {
    setLoading(true);
    const startDate = format(new Date(datePicker), "yyyy-MM-dd");
    const endDate = format(new Date(addDays(startDate, 1)), "yyyy-MM-dd");
    let tzDiff = getTimezoneOffset("Europe/Dublin", startDate) / 1000 / 60 / 60;
    let newStartDate;
    let newEndDate;
    if (tzDiff === 1) {
      newStartDate =
        format(new Date(addDays(startDate, -1)), "yyyy-MM-dd") + "T23%3A00z";
      newEndDate = startDate + "T22%3A30z";
    } else {
      newStartDate = startDate + "T00%3A00z";
      newEndDate = endDate + "T23%3A30z";
    }

    const getData = async () => {
      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/nonbm/disbsad/summary?from=${newStartDate}&to=${newEndDate}&format=json`
      );
      let data1 = await response.json();
      console.log(data1);

      //   const data = [
      //     [
      //       "SP",
      //       "Start Time",
      //       "Buy Auction Count",
      //       "Sell Auction Count",
      //       "Buy Price Min",
      //       "Buy Price Max",
      //       "Buy Price Average",
      //       "Sell Price Min",
      //       "Sell Price Max",
      //       "Sell Price Average",
      //       "Buy Volume Total",
      //       "Sell Volume Total",
      //       "Net Volume",
      //     ],
      //   ];
      //   let temp = [];
      //   for (let a = 0; a < data1.data.length; a++) {
      //     temp = [];
      //     temp.push(data1.data[a].settlementPeriod);
      //     temp.push(data1.data[a].startTime);
      //     temp.push(data1.data[a].buyActionCount);
      //     temp.push(data1.data[a].sellActionCount);
      //     temp.push(data1.data[a].buyPriceMinimum);
      //     temp.push(data1.data[a].buyPriceMaximum);
      //     temp.push(data1.data[a].buyPriceAverage);
      //     temp.push(data1.data[a].sellPriceMinimum);
      //     temp.push(data1.data[a].sellPriceMaximum);
      //     temp.push(data1.data[a].sellPriceAverage);
      //     temp.push(data1.data[a].buyVolumeTotal);
      //     temp.push(data1.data[a].sellVolumeTotal);
      //     temp.push(data1.data[a].netVolume);
      //     data.push(temp);
      //   }
      //   setTableData(data);

      const fontSize = {
        fontSize: "12px",

        backgroundColor: "#2e86c1",
        position: "sticky",
        top: "50px",
      };
      const fontSize2 = { fontSize: "12px" };
      const buttonStyle = { fontSize: "12px", height: "18px" };
      const content = (
        <>
          <div className="container ">
            <table className="table table-condensed table-striped table-bordered rounded">
              {/* <thead style={{ position: "sticky top: 0" }}> */}
              <thead>
                <tr>
                  <th style={fontSize}>Settlement Period Click for Detail</th>
                  <th style={fontSize}>Buy Action Count</th>
                  <th style={fontSize}>Sell Action Count</th>
                  <th style={fontSize}>Buy Price Minimum</th>
                  <th style={fontSize}>Buy Price Maximum</th>
                  <th style={fontSize}>Buy Price Average</th>
                  <th style={fontSize}>Sell Price Minimum</th>
                  <th style={fontSize}>Sell Price Maximum</th>
                  <th style={fontSize}>Sell Price Average</th>
                  <th style={fontSize}>Buy Volume Total</th>
                  <th style={fontSize}>Sell Volume Total</th>
                  <th style={fontSize}>Net Volume</th>
                </tr>
              </thead>
              <tbody>
                {data1.data?.map((data, i) => (
                  <tr key={i}>
                    <td style={fontSize2}>
                      {
                        <button
                          style={buttonStyle}
                          className="btn btn-primary pt-0"
                          onClick={() => {
                            detailClicked(data.settlementPeriod);
                          }}
                        >
                          {data.settlementPeriod}
                        </button>
                      }
                    </td>
                    {/* <td style={fontSize2}>{data.createdDateTime}</td> */}
                    <td style={fontSize2}>{data.buyActionCount}</td>
                    <td style={fontSize2}>{data.sellActionCount}</td>
                    <td style={fontSize2}>{data.buyPriceMinimum}</td>
                    <td style={fontSize2}>{data.buyPriceMaximum}</td>
                    <td style={fontSize2}>{data.buyPriceAverage}</td>
                    <td style={fontSize2}>{data.sellPriceMinimum}</td>
                    <td style={fontSize2}>{data.sellPriceMaximum}</td>
                    <td style={fontSize2}>{data.sellPriceAverage}</td>
                    <td style={fontSize2}>{data.buyVolumeTotal}</td>
                    <td style={fontSize2}>{data.sellVolumeTotal}</td>
                    <td style={fontSize2}>{data.netVolume}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
      setTableData(content);
      setLoading(false);
    };

    getData();
  }, [datePicker]);

  const detailClicked = (e) => {
    console.log(e);
    setSp(e);
    setDetailModalShow(true);
    //navigate(`/dash/bookings/`);
  };
  const closeDetailModal = () => {
    setDetailModalShow(false);
  };

  const options = {
    allowHtml: true,
    showRowNumber: false,
  };

  const formatters = [
    {
      column: 1,
      options: {
        prefix: "$",
        negativeColor: "red",
        negativeParens: true,
      },
    },
  ];

  const customStyles = {
    content: {
      top: "15%",
      left: "20%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      //transform: "translate(-50%, -50%)",
    },
  };
  console.log(detailModalShow);
  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader loading={loading} />{" "}
        </div>
      ) : (
        <div>
          <div>{tableData}</div>
          <DetailDisbsad
            detailModalShow={detailModalShow}
            closeDetailModal={closeDetailModal}
            sp={sp}
            // sp={sp}
          />
        </div>
      )}
    </>
  );
};

export default Disbsad;
