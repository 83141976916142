import React from "react";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import MainContext from "../context/MainContext";
import {
  addDays,
  subDays,
  subHours,
  format,
  getDate,
  getMonth,
} from "date-fns";
import { ClipLoader } from "react-spinners";

import { getTimezoneOffset } from "date-fns-tz";

const DerateMargin = () => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [fullContent, setFullContent] = useState([]);
  const startDate = format(new Date(datePicker), "yyyy-MM-dd");
  const endDate = format(new Date(addDays(datePicker, 1)), "yyyy-MM-dd");

  const startDateTime = startDate + "T12%3A00";
  const endDateTime = endDate + "T00%3A00";
  const startDateTime1 = startDate + "T00%3A00";
  const endDateTime1 = startDate + "T12%3A00";

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/datasets/LOLPDRM/stream?publishDateTimeFrom=${startDateTime}&publishDateTimeTo=${endDateTime}`
        //`https://data.elexon.co.uk/bmrs/api/v1/forecast/system/loss-of-load?from=${startDateTime}&to=${startDateTime}&format=json`
      );
      const data = await response.json();
      //console.log(data);
      const uniqueArray = [];
      data.forEach((item) => {
        if (!uniqueArray.includes(item.publishingPeriodCommencingTime)) {
          uniqueArray.push(item.publishingPeriodCommencingTime);
        }
      });
      const response1 = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/datasets/LOLPDRM/stream?publishDateTimeFrom=${startDateTime1}&publishDateTimeTo=${endDateTime1}`
        //`https://data.elexon.co.uk/bmrs/api/v1/forecast/system/loss-of-load?from=${startDateTime1}&to=${startDateTime1}&format=json`
      );
      const data1 = await response1.json();
      //console.log(data1);
      const uniqueArray1 = [];
      data1.forEach((item) => {
        if (!uniqueArray1.includes(item.publishingPeriodCommencingTime)) {
          uniqueArray1.push(item.publishingPeriodCommencingTime);
        }
      });

      let data3 = [];
      data3.push(...data);
      data3.push(...data1);
      console.log(data3);

      //console.log(uniqueArray);
      //console.log(uniqueArray1);
      let newDerateArray = [];
      newDerateArray.push(...uniqueArray);
      newDerateArray.push(...uniqueArray1);

      console.log(newDerateArray);

      let temp = [];

      let newData;

      setFullContent([]);

      const GetChart = async (data, i, b) => {
        const key = "A" + i;

        let chartData = [
          [
            { type: "datetime", label: "Date Time" },
            { type: "number", label: "Derate Margin" },
            { type: "number", label: "LOLPDM" },
            // { type: "number", label: "Forecast Horizon" },
          ],
        ];
        temp = [];
        for (let a = 0; a < newData.length; a++) {
          temp = [];
          temp.push(new Date(newData[a].startTime));
          temp.push(newData[a].deratedMargin);
          temp.push(newData[a].lossOfLoadProbability);
          // temp.push(newData[a].forecastHorizon);
          chartData.push(temp);
        }
        console.log(chartData);
        let options = {
          title: `Publishing Period Commencing Time ${b} `,
          height: 399,
          width: 749,
          focusTarget: "category",
          series: {
            0: { lineWidth: 2, pointSize: 2.1 },
            1: { targetAxisIndex: 1, type: "bars" },
            // 2: { targetAxisIndex: 1, type: "bars" },
          },
          hAxis: {
            textStyle: { fontSize: 10 },
            title: "Date Time",
            format: "dd-MM-yy HH:mm",
            slantedText: true,
            slantedTextAngle: 45,
          },
          vAxes: {
            0: { title: "Derate Msrgin" },
            1: { title: "LOLPDM", viewWindow: { min: 0, max: 1 } },
          },

          chartArea: { backgroundColor: "#fffff0" },
        };

        //console.log(newData);

        return (
          <div
            key={key}
            style={{
              height: "400px",
              width: "750px",
              border: "1px solid #DCDCDC",
              float: "left",
            }}
          >
            <Chart chartType="LineChart" options={options} data={chartData} />
          </div>
        );
      }; //getChart end

      let promises = [];

      for (let i = 0; i < newDerateArray.length; i++) {
        newData = data3
          .filter((x) => x.publishingPeriodCommencingTime === newDerateArray[i])
          .sort((a, b) => new Date(a.startTime) - new Date(b.startTime));

        promises.push(
          GetChart(
            newData,
            i,
            format(new Date(newDerateArray[i]), "dd-MMM-yyyy HH:mm")
          )
        );
      }
      Promise.all(promises).then((arrOfResults) => {
        setFullContent([]);
        for (let i = 0; i < arrOfResults.length; i++) {
          setFullContent((current) => [...current, arrOfResults[i]]);
        }
      });
      //console.log(contents);
      //setFullContent(contents);
      setLoading(false);
    }; //end of getsData

    getData();
  }, [datePicker]);

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader loading={loading} />{" "}
        </div>
      ) : (
        <>
          <div style={{ textAlign: "center" }}>
            <h4>
              This data is published multiple times throughout the day with the
              latest at the top. Start times roll forward with each update{" "}
            </h4>
          </div>
          <div>{fullContent}</div>
        </>
      )}
    </>
  );
};

export default DerateMargin;
