import React from "react";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import MainContext from "../context/MainContext";
import { addDays, format, getDate, getMonth } from "date-fns";
import { ClipLoader } from "react-spinners";
import { getTimezoneOffset } from "date-fns-tz";

const ActualMetered = () => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [data, setData] = useState([]);
  const [content, setContent] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);

      const localStartDate = format(new Date(datePicker), "yyyy-MM-dd");
      let startDateTzDiff =
        getTimezoneOffset("Europe/Dublin", localStartDate) / 1000 / 60 / 60;
      const localEndDate = format(
        new Date(addDays(datePicker, 1)),
        "yyyy-MM-dd"
      );
      let endDateTzDiff =
        getTimezoneOffset("Europe/Dublin", localEndDate) / 1000 / 60 / 60;
      let startDate;
      let endDate;
      if (startDateTzDiff === 1) {
        startDate =
          format(new Date(addDays(localStartDate, -1)), "yyyy-MM-dd") +
          "T23%3A00z";
      } else {
        startDate = localStartDate + "T00%3A00z";
      }
      if (endDateTzDiff === 1) {
        endDate =
          format(new Date(addDays(localEndDate, -1)), "yyyy-MM-dd") +
          "T22%3A30z";
      } else {
        endDate =
          format(new Date(addDays(localEndDate, -1)), "yyyy-MM-dd") +
          "T23%3A30z";
      }

      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/reference/bmunits/all`
      );
      const bmus = await response.json();
      console.log(bmus);
      const bmuList = bmus.filter(
        (x) => x.fuelType === id && x.fpnFlag === true
      );
      console.log(bmuList);

      const getChart = async (i, bmu, startDate, endDate) => {
        console.log(i, bmu, startDate, startDate);
        const response = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/datasets/B1610/stream?from=${startDate}&to=${endDate}&bmUnit=${bmu}`
        );
        const metered = await response.json();

        let chartData = [
          [
            { type: "string", label: "Date Time" },
            { type: "number", label: "Metered" },
          ],
        ];
        let temp = [];
        for (let a = 0; a < metered.length; a++) {
          temp = [];
          temp.push(metered[a].settlementPeriod);
          temp.push(metered[a].quantity);
          chartData.push(temp);
        }

        const options = {
          title: bmu,

          width: 495,
          height: 300,
          series: {
            0: {
              type: "bars",
            },
          },
          hAxis: {
            textStyle: { fontSize: 10 },
            title: "Date",
          },
          vAxes: {
            0: { title: "Metered Output MWh" },
          },
          chartArea: { backgroundColor: "#fffff0" },
        };

        return (
          <div
            key={i}
            style={{
              height: "340px",
              width: "500px",
              border: "1px solid #DCDCDC",
              float: "left",
            }}
          >
            <Chart chartType="LineChart" options={options} data={chartData} />
          </div>
        );
      };

      let chart;
      let promises = [];

      for (let i = 0; i < bmuList.length; i++) {
        let bmu;
        if (bmuList[i].elexonBmUnit === null) {
          bmu = bmuList[i].nationalGridBmUnit;
        } else {
          bmu = bmuList[i].elexonBmUnit;
        }
        console.log(i, bmuList[i].elexonBmUnit, startDate, startDate);
        promises.push(getChart(i, bmu, startDate, endDate));
      }
      Promise.all(promises).then((arrOfResults) => {
        setContent([]);
        for (let i = 0; i < arrOfResults.length; i++) {
          setContent((current) => [...current, arrOfResults[i]]);
        }
      });
      setLoading(false);
    };
    getData();
  }, [id, datePicker]);

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader loading={loading} />{" "}
        </div>
      ) : (
        <>
          <b>
            This Data is published five days after the end of the operational
            period based on the Interim Information (II) Settlement Run and the
            data is refreshed by subsequent Settlement Runs{" "}
          </b>
          <div>{content}</div>
        </>
      )}
    </>
  );
};

export default ActualMetered;
