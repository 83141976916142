import React from "react";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import MainContext from "../context/MainContext";
import { addDays, format, getDate, getMonth } from "date-fns";
import { ClipLoader } from "react-spinners";
import BodModal from "./BodModal";
import IndicativeModal from "./IndicativeModal2";
import DynamicDataModal from "./DynamicDataModal";
import RemitModal from "./RemitModal";
import { getTimezoneOffset } from "date-fns-tz";

const Bmu6 = () => {
  const [bmuList, setBmuList] = useState([]);
  const [content, setContent] = useState([]);
  const [bodModalShow, setBodModalShow] = useState(false);
  const [indicModalShow, setIndicModalShow] = useState(false);
  const [dynamicModalShow, setDynamicModalShow] = useState(false);
  const [remitModalShow, setRemitModalShow] = useState(false);
  const [bmuName, setBmuName] = useState("");
  const [message, setMessage] = useState("");
  const { id } = useParams();

  console.log(id);
  const { datePicker, loading, setLoading } = useContext(MainContext);
  //console.log(datePicker);

  useEffect(() => {
    setContent([]);
    setLoading(true);
    //fetch("https://192.168.1.171:3443/bmu", {
    //fetch("https://192.168.1.171:3500/bmu", {
    //headers: { "Access-Control-Allow-Private-Network": "true" },
    //})
    fetch("https://data.elexon.co.uk/bmrs/api/v1/reference/bmunits/all")
      .then((response) => response.json())
      .then((data) => {
        // console.log(
        //   data
        //     .filter((x) => x.fpnFlag === true)
        //     .sort((a, b) => a.leadPartyName.localeCompare(b.leadPartyName))
        // );
        //data.sort((a, b) => a.leadPartyName - b.leadPartyName);
        if (id === "nullE") {
          setBmuList(
            data
              .filter(
                (x) =>
                  x.fuelType === null &&
                  x.fpnFlag === true &&
                  x.bmUnitType === "E"
              )
              .sort((a, b) =>
                a.leadPartyName.toLowerCase() < b.leadPartyName.toLowerCase()
                  ? -1
                  : 1
              )
          );
        } else if (id === "nullS") {
          setBmuList(
            data
              .filter(
                (x) =>
                  x.fuelType === null &&
                  x.fpnFlag === true &&
                  x.bmUnitType === "S"
              )
              .sort((a, b) =>
                a.leadPartyName.toLowerCase() < b.leadPartyName.toLowerCase()
                  ? -1
                  : 1
              )
          );
        } else if (id === "nullV") {
          setBmuList(
            data
              .filter(
                (x) =>
                  x.fuelType === null &&
                  x.fpnFlag === true &&
                  x.bmUnitType === "V"
              )
              .sort((a, b) =>
                a.leadPartyName.toLowerCase() < b.leadPartyName.toLowerCase()
                  ? -1
                  : 1
              )
          );
        } else if (id === "nullT") {
          setBmuList(
            data
              .filter(
                (x) =>
                  x.fuelType === null &&
                  x.fpnFlag === true &&
                  x.bmUnitType === "T"
              )
              .sort((a, b) =>
                a.leadPartyName.toLowerCase() < b.leadPartyName.toLowerCase()
                  ? -1
                  : 1
              )
          );
        } else if (id === "nullG") {
          setBmuList(
            data
              .filter(
                (x) =>
                  x.fuelType === null &&
                  x.fpnFlag === true &&
                  x.bmUnitType === "G"
              )
              .sort((a, b) =>
                a.leadPartyName.toLowerCase() < b.leadPartyName.toLowerCase()
                  ? -1
                  : 1
              )
          );
        } else {
          setBmuList(
            data
              .filter((x) => x.fuelType === id && x.fpnFlag === true)
              .sort((a, b) =>
                a.leadPartyName.toLowerCase() < b.leadPartyName.toLowerCase()
                  ? -1
                  : 1
              )
          );
        }
      });
  }, [id, datePicker]);

  useEffect(() => {
    setLoading(true);
    const localStartDate = format(new Date(datePicker), "yyyy-MM-dd");
    let startDateTzDiff =
      getTimezoneOffset("Europe/Dublin", localStartDate) / 1000 / 60 / 60;
    const localEndDate = format(new Date(addDays(datePicker, 1)), "yyyy-MM-dd");
    let endDateTzDiff =
      getTimezoneOffset("Europe/Dublin", localEndDate) / 1000 / 60 / 60;

    let startDate;
    let endDate;
    if (startDateTzDiff === 1) {
      startDate =
        format(new Date(addDays(localStartDate, -1)), "yyyy-MM-dd") +
        "T23%3A30z";
    } else {
      startDate = localStartDate + "T00%3A30z";
    }
    if (endDateTzDiff === 1) {
      endDate =
        format(new Date(addDays(localEndDate, -1)), "yyyy-MM-dd") + "T23%3A30z";
    } else {
      endDate = localEndDate + "T00%3A30z";
    }

    const yr = new Date(startDate).getFullYear();
    const month = new Date(startDate).getMonth();
    const day = new Date(startDate).getDate();

    //console.log(startDate);
    //console.log(endDate);
    const getData = async (e, f, id) => {
      setMessage(`${e} Requesting Data`);
      //console.log(e);
      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/physical?bmUnit=${e}&from=${startDate}&to=${endDate}&dataset=PN&dataset=MILS&dataset=MELS&format=json`
        //`http://localhost:3500/bmu/physical/${e}/y/${yr}/m/${month}/d/${day}`
      );
      let data = await response.json();
      setMessage(`${e} PN, MEL & MIL data recieved`);
      console.log(data.data);
      if (data.data.length > 0) {
        const response2 = await fetch(
          `https://data.elexon.co.uk/bmrs/api/v1/balancing/acceptances?bmUnit=${e}&from=${startDate}&to=${endDate}&format=json`
          //`http://localhost:3500/bmu/acceptances/${e}/y/${yr}/m/${month}/d/${day}`
        );
        setMessage(`${e} acceptance data recieved`);
        let data2 = await response2.json();
        console.log(data2.data);

        //console.log(data.data);
        const sd = new Date(datePicker);
        const y = sd.getFullYear();
        const m = sd.getMonth();
        const d = sd.getDate();
        let options;
        let chartData;
        let temp;
        let dateStr;
        let y1;
        let m1;
        let d1;
        let h1;
        let min1;
        let milBoa;
        let boa;
        let mil;

        let test;
        if (data2.data.length > 0) {
          if (
            id === "PS" ||
            id === "OTHER" ||
            id === "nullE" ||
            id === "nullS" ||
            id === "nullV" ||
            id === "nullT" ||
            id === "nullG"
          ) {
            chartData = [["Time", "PN", "MEL", "MIL", "BOA"]];
            milBoa = true;
            mil = true;
          } else {
            chartData = [["Time", "PN", "MEL", "BOA"]];
          }
          //chartData = [["Time", "PN", "MEL"]];

          const pn = data.data.filter((x) => x.dataset === "PN");
          pn.sort(
            (a, b) =>
              new Date(a.timeFrom).getTime() - new Date(b.timeFrom).getTime()
          );
          //console.log(pn);
          for (let a = 0; a < pn.length; a++) {
            temp = [];
            dateStr = pn[a].timeFrom;
            y1 = new Date(dateStr).getFullYear();
            m1 = new Date(dateStr).getMonth();
            d1 = new Date(dateStr).getDate();
            h1 = new Date(dateStr).getHours();
            min1 = new Date(dateStr).getMinutes();

            temp.push(new Date(y1, m1, d1, h1, min1, 0));
            temp.push(pn[a].levelFrom);
            temp.push(null);
            if (milBoa) {
              temp.push(null);
              temp.push(null);
            } else {
              temp.push(null);
            }
            chartData.push(temp);

            temp = [];
            dateStr = pn[a].timeTo;
            y1 = new Date(dateStr).getFullYear();
            m1 = new Date(dateStr).getMonth();
            d1 = new Date(dateStr).getDate();
            h1 = new Date(dateStr).getHours();
            min1 = new Date(dateStr).getMinutes();
            temp.push(new Date(y1, m1, d1, h1, min1, 0));
            temp.push(pn[a].levelTo);
            temp.push(null);
            if (milBoa) {
              temp.push(null);
              temp.push(null);
            } else {
              temp.push(null);
            }
            chartData.push(temp);
          }

          const mel = data.data.filter((x) => x.dataset === "MELS");
          mel.sort(
            (a, b) =>
              new Date(a.timeFrom).getTime() - new Date(b.timeFrom).getTime()
          );
          //console.log(mel);
          for (let a = 0; a < mel.length; a++) {
            temp = [];
            dateStr = mel[a].timeFrom;
            y1 = new Date(dateStr).getFullYear();
            m1 = new Date(dateStr).getMonth();
            d1 = new Date(dateStr).getDate();
            h1 = new Date(dateStr).getHours();
            min1 = new Date(dateStr).getMinutes();
            temp.push(new Date(y1, m1, d1, h1, min1, 0));
            temp.push(null);
            temp.push(mel[a].levelFrom);
            if (milBoa) {
              temp.push(null);
              temp.push(null);
            } else {
              temp.push(null);
            }

            chartData.push(temp);
            temp = [];
            dateStr = mel[a].timeTo;
            y1 = new Date(dateStr).getFullYear();
            m1 = new Date(dateStr).getMonth();
            d1 = new Date(dateStr).getDate();
            h1 = new Date(dateStr).getHours();
            min1 = new Date(dateStr).getMinutes();
            temp.push(new Date(y1, m1, d1, h1, min1, 0));
            temp.push(null);
            temp.push(mel[a].levelTo);
            if (milBoa) {
              temp.push(null);
              temp.push(null);
            } else {
              temp.push(null);
            }
            chartData.push(temp);
          }
          if (milBoa) {
            const mil = data.data.filter((x) => x.dataset === "MILS");
            //console.log(mel);
            mil.sort(
              (a, b) =>
                new Date(a.timeFrom).getTime() - new Date(b.timeFrom).getTime()
            );
            for (let a = 0; a < mil.length; a++) {
              temp = [];
              dateStr = mil[a].timeFrom;
              y1 = new Date(dateStr).getFullYear();
              m1 = new Date(dateStr).getMonth();
              d1 = new Date(dateStr).getDate();
              h1 = new Date(dateStr).getHours();
              min1 = new Date(dateStr).getMinutes();
              temp.push(new Date(y1, m1, d1, h1, min1, 0));
              temp.push(null);
              temp.push(null);
              temp.push(mil[a].levelFrom);
              temp.push(null);

              chartData.push(temp);
              temp = [];
              dateStr = mil[a].timeTo;
              y1 = new Date(dateStr).getFullYear();
              m1 = new Date(dateStr).getMonth();
              d1 = new Date(dateStr).getDate();
              h1 = new Date(dateStr).getHours();
              min1 = new Date(dateStr).getMinutes();
              temp.push(new Date(y1, m1, d1, h1, min1, 0));
              temp.push(null);
              temp.push(null);
              temp.push(mil[a].levelTo);
              temp.push(null);

              chartData.push(temp);
            }
          }
          data2.data.sort(
            (a, b) => new Date(a.timeFrom) - new Date(b.timeFrom)
          );
          console.log(data2.data);
          let BoaId;
          for (let a = 0; a < data2.data.length; a++) {
            if (data2.data[a].acceptanceTime === BoaId || a === 0) {
              temp = [];
              dateStr = data2.data[a].timeFrom;
              y1 = new Date(dateStr).getFullYear();
              m1 = new Date(dateStr).getMonth();
              d1 = new Date(dateStr).getDate();
              h1 = new Date(dateStr).getHours();
              min1 = new Date(dateStr).getMinutes();
              temp.push(new Date(y1, m1, d1, h1, min1, 0));
              temp.push(null);
              temp.push(null);
              if (milBoa) {
                temp.push(null);
              }
              temp.push(data2.data[a].levelFrom);
              chartData.push(temp);

              temp = [];
              dateStr = data2.data[a].timeTo;
              y1 = new Date(dateStr).getFullYear();
              m1 = new Date(dateStr).getMonth();
              d1 = new Date(dateStr).getDate();
              h1 = new Date(dateStr).getHours();
              min1 = new Date(dateStr).getMinutes();
              temp.push(new Date(y1, m1, d1, h1, min1, 0));

              temp.push(null);
              temp.push(null);
              if (milBoa) {
                temp.push(null);
              }
              temp.push(data2.data[a].levelTo);
              chartData.push(temp);
              BoaId = data2.data[a].acceptanceTime;
            } else {
              temp = [];
              dateStr = data2.data[a].timeFrom;
              y1 = new Date(dateStr).getFullYear();
              m1 = new Date(dateStr).getMonth();
              d1 = new Date(dateStr).getDate();
              h1 = new Date(dateStr).getHours();
              min1 = new Date(dateStr).getMinutes();
              temp.push(new Date(y1, m1, d1, h1, min1, 0));
              temp.push(null);
              temp.push(null);
              if (milBoa) {
                temp.push(null);
              }
              temp.push(null);
              chartData.push(temp);

              temp = [];
              temp.push(new Date(y1, m1, d1, h1, min1, 0));
              temp.push(null);
              temp.push(null);
              if (milBoa) {
                temp.push(null);
              }
              temp.push(data2.data[a].levelFrom);
              chartData.push(temp);

              temp = [];
              dateStr = data2.data[a].timeTo;
              y1 = new Date(dateStr).getFullYear();
              m1 = new Date(dateStr).getMonth();
              d1 = new Date(dateStr).getDate();
              h1 = new Date(dateStr).getHours();
              min1 = new Date(dateStr).getMinutes();
              temp.push(new Date(y1, m1, d1, h1, min1, 0));

              temp.push(null);
              temp.push(null);
              if (milBoa) {
                temp.push(null);
              }
              temp.push(data2.data[a].levelTo);
              chartData.push(temp);
              BoaId = data2.data[a].acceptanceTime;
            }
          }
          //console.log(chartData);
        } else {
          //No BOAs
          if (mil) {
            chartData = [["Time", "PN", "MEL", "MIL"]];
          } else {
            chartData = [["Time", "PN", "MEL"]];
          }
          const pn = data.data.filter((x) => x.dataset === "PN");
          pn.sort(
            (a, b) =>
              new Date(a.timeFrom).getTime() - new Date(b.timeFrom).getTime()
          );
          //console.log(pn);
          for (let a = 0; a < pn.length; a++) {
            temp = [];
            dateStr = pn[a].timeFrom;
            y1 = new Date(dateStr).getFullYear();
            m1 = new Date(dateStr).getMonth();
            d1 = new Date(dateStr).getDate();
            h1 = new Date(dateStr).getHours();
            min1 = new Date(dateStr).getMinutes();

            temp.push(new Date(y1, m1, d1, h1, min1, 0));
            temp.push(pn[a].levelFrom);
            temp.push(null);
            if (mil) {
              temp.push(null);
            }
            chartData.push(temp);

            temp = [];
            dateStr = pn[a].timeTo;
            y1 = new Date(dateStr).getFullYear();
            m1 = new Date(dateStr).getMonth();
            d1 = new Date(dateStr).getDate();
            h1 = new Date(dateStr).getHours();
            min1 = new Date(dateStr).getMinutes();
            temp.push(new Date(y1, m1, d1, h1, min1, 0));
            temp.push(pn[a].levelTo);
            temp.push(null);
            if (mil) {
              temp.push(null);
            }
            chartData.push(temp);
          }

          const mel = data.data.filter((x) => x.dataset === "MELS");
          mel.sort(
            (a, b) =>
              new Date(a.timeFrom).getTime() - new Date(b.timeFrom).getTime()
          );
          //console.log(mel);
          for (let a = 0; a < mel.length; a++) {
            temp = [];
            dateStr = mel[a].timeFrom;
            y1 = new Date(dateStr).getFullYear();
            m1 = new Date(dateStr).getMonth();
            d1 = new Date(dateStr).getDate();
            h1 = new Date(dateStr).getHours();
            min1 = new Date(dateStr).getMinutes();
            temp.push(new Date(y1, m1, d1, h1, min1, 0));
            temp.push(null);

            temp.push(mel[a].levelFrom);
            if (mil) {
              temp.push(null);
            }

            chartData.push(temp);
            temp = [];
            dateStr = mel[a].timeTo;
            y1 = new Date(dateStr).getFullYear();
            m1 = new Date(dateStr).getMonth();
            d1 = new Date(dateStr).getDate();
            h1 = new Date(dateStr).getHours();
            min1 = new Date(dateStr).getMinutes();
            temp.push(new Date(y1, m1, d1, h1, min1, 0));
            temp.push(null);
            temp.push(mel[a].levelTo);
            if (mil) {
              temp.push(null);
            }

            chartData.push(temp);
          }
          if (mil) {
            const mil = data.data.filter((x) => x.dataset === "MILS");
            //console.log(mel);
            mil.sort(
              (a, b) =>
                new Date(a.timeFrom).getTime() - new Date(b.timeFrom).getTime()
            );
            for (let a = 0; a < mil.length; a++) {
              temp = [];
              dateStr = mil[a].timeFrom;
              y1 = new Date(dateStr).getFullYear();
              m1 = new Date(dateStr).getMonth();
              d1 = new Date(dateStr).getDate();
              h1 = new Date(dateStr).getHours();
              min1 = new Date(dateStr).getMinutes();
              temp.push(new Date(y1, m1, d1, h1, min1, 0));
              temp.push(null);
              temp.push(null);
              temp.push(mil[a].levelFrom);

              chartData.push(temp);
              temp = [];
              dateStr = mil[a].timeTo;
              y1 = new Date(dateStr).getFullYear();
              m1 = new Date(dateStr).getMonth();
              d1 = new Date(dateStr).getDate();
              h1 = new Date(dateStr).getHours();
              min1 = new Date(dateStr).getMinutes();
              temp.push(new Date(y1, m1, d1, h1, min1, 0));
              temp.push(null);
              temp.push(null);
              temp.push(mil[a].levelTo);
              chartData.push(temp);
            }
          }
          //console.log(chartData);
        }

        //console.log(chartData);
        /* test = [
          ["Time", "PN", "MEL"],
          [new Date(2024, 5, 8, 2, 0, 0), 34, null],
          [new Date(2024, 5, 8, 4, 0, 0), 36, null],
          [new Date(2024, 5, 8, 6, 0, 0), 36, null],
          [new Date(2024, 5, 8, 8, 0, 0), 39, null],
          [new Date(2024, 5, 8, 10, 0, 0), 40, null],
          [new Date(2024, 5, 8, 3, 0, 0), null, 100],
          [new Date(2024, 5, 8, 5, 0, 0), null, 100],
          [new Date(2024, 5, 8, 7, 0, 0), null, 100],
          [new Date(2024, 5, 8, 9, 0, 0), null, 100],
          [new Date(2024, 5, 8, 11, 0, 0), null, 100],
        ]; */
        console.log(chartData);
        test = [
          ["Time", "PN", "MEL", "BOA"],
          [new Date(2024, 5, 8, 2, 0, 0), 34, null, null],
          [new Date(2024, 5, 8, 4, 0, 0), 36, null, null],
          [new Date(2024, 5, 8, 6, 0, 0), 36, null, null],
          [new Date(2024, 5, 8, 8, 0, 0), 39, null, null],
          [new Date(2024, 5, 8, 10, 0, 0), 40, null, null],
          [new Date(2024, 5, 8, 3, 0, 0), null, 100, null],
          [new Date(2024, 5, 8, 5, 0, 0), null, 100, null],
          [new Date(2024, 5, 8, 7, 0, 0), null, 100, null],
          [new Date(2024, 5, 8, 9, 0, 0), null, 100, null],
          [new Date(2024, 5, 8, 11, 0, 0), null, 100, null],
          /*  [new Date(2024, 5, 8, 6, 0, 0), null, null, 150],
          [new Date(2024, 5, 8, 10, 0, 0), null, null, 150], */
        ];
        if (
          id === "PS" ||
          id === "OTHER" ||
          id === "nullE" ||
          id === "nullS" ||
          id === "nullV" ||
          id === "nullT" ||
          id === "nullG"
        ) {
          options = {
            title: `${e} - ${f}`,
            width: 495,
            height: 300,
            series: {
              0: { lineWidth: 1, pointSize: 1.1 },
              1: { lineWidth: 1, pointSize: 1.1 },
              2: { lineWidth: 1, pointSize: 1.1, color: "green" },
              3: { lineWidth: 1, pointSize: 1.1, color: "#ffc40c" },
            },
            hAxis: {
              textStyle: { fontSize: 10 },
              format: "HH:mm",
              minValue: new Date(y, m, d, 0, 0, 0),
              maxValue: new Date(y, m, d + 1, 0, 0, 0),

              viewWindow: {
                min: new Date(y, m, d, 0, 0, 0),
                max: new Date(y, m, d + 1, 0, 0, 0),
              },
            },
            vAxis: {
              textStyle: { fontSize: 10 },
              /*  minValue: 0,
            maxValue: 1000, */
            },
            chartArea: { backgroundColor: "#fffff0" },
          };
        } else if (id === "WIND" || id === "OCGT" || id === "NPSHYD") {
          options = {
            title: `${e} - ${f}`,
            width: 495,
            height: 300,
            series: {
              0: { lineWidth: 1, pointSize: 1.1 },
              1: { lineWidth: 1, pointSize: 1.1 },
              2: { lineWidth: 1, pointSize: 1.1 },
              3: { lineWidth: 1, pointSize: 1.1 },
            },
            hAxis: {
              textStyle: { fontSize: 10 },
              format: "HH:mm",
              minValue: new Date(y, m, d, 0, 0, 0),
              maxValue: new Date(y, m, d + 1, 0, 0, 0),

              viewWindow: {
                min: new Date(y, m, d, 0, 0, 0),
                max: new Date(y, m, d + 1, 0, 0, 0),
              },
            },
            vAxis: {
              textStyle: { fontSize: 10 },
              minValue: 0,
              //maxValue: 1000,
            },
            chartArea: { backgroundColor: "#fffff0" },
          };
        } else {
          options = {
            title: `${e} - ${f}`,
            width: 495,
            height: 300,
            series: {
              0: { lineWidth: 1, pointSize: 1.1 },
              1: { lineWidth: 1, pointSize: 1.1 },
              2: { lineWidth: 1, pointSize: 1.1 },
              3: { lineWidth: 1, pointSize: 1.1 },
            },
            hAxis: {
              textStyle: { fontSize: 10 },
              format: "HH:mm",
              minValue: new Date(y, m, d, 0, 0, 0),
              maxValue: new Date(y, m, d + 1, 0, 0, 0),

              viewWindow: {
                min: new Date(y, m, d, 0, 0, 0),
                max: new Date(y, m, d + 1, 0, 0, 0),
              },
            },
            vAxis: {
              textStyle: { fontSize: 10 },
              minValue: 0,
              maxValue: 1000,
            },
            chartArea: { backgroundColor: "#fffff0" },
          };
        }
        //console.log(test);
        //console.log(options);

        return (
          <div
            key={e}
            style={{
              height: "340px",
              width: "500px",
              border: "1px solid #DCDCDC",
              float: "left",
            }}
          >
            <Chart
              chartType="ScatterChart"
              options={options}
              data={chartData}
            />
            <button
              style={{ fontSize: "12px", height: "30px" }}
              className="btn btn-primary mr-4"
              onClick={() => {
                bodClicked(e);
              }}
            >
              BOD Data
            </button>
            <button
              style={{ fontSize: "12px", height: "30px" }}
              className="btn btn-primary  mr-4"
              onClick={() => {
                indicClicked(e);
              }}
            >
              Indicative Data
            </button>
            <button
              style={{ fontSize: "12px", height: "30px" }}
              className="btn btn-primary  mr-4"
              onClick={() => {
                dynamicClicked(e);
              }}
            >
              Dynamic Data
            </button>
            <button
              style={{ fontSize: "12px", height: "30px" }}
              className="btn btn-primary  mr-4"
              onClick={() => {
                remitClicked(e);
              }}
            >
              Remit
            </button>
          </div>
        );
      }
    };

    let promises = [];
    //console.log(bmuList);
    for (let i = 0; i < bmuList.length; i++) {
      //for (let i = 0; i < 1; i++) {
      //console.log(bmuList[i].elexonBmUnit);
      promises.push(
        getData(bmuList[i].elexonBmUnit, bmuList[i].leadPartyName, id)
        //getData("T_ROCK-1", "test", id)
      );
    }
    //console.log(promises);
    Promise.all(promises).then((arrOfResults) => {
      setContent([]);
      for (let i = 0; i < arrOfResults.length; i++) {
        setContent((current) => [...current, arrOfResults[i]]);
        //console.log(content);
      }
      setLoading(false);
    });
  }, [bmuList]);

  const bodClicked = async (bmu) => {
    //console.log(bmu);
    setBodModalShow(true);
    setBmuName(bmu);
  };
  const closeBodModal = () => {
    setBodModalShow(false);
  };
  const indicClicked = async (bmu) => {
    //console.log(bmu);
    setIndicModalShow(true);
    //setBmuName("");
    setBmuName(bmu);
  };
  const closeIndicModal = () => {
    setIndicModalShow(false);
  };

  const dynamicClicked = async (bmu) => {
    //console.log(bmu);
    setDynamicModalShow(true);
    //setBmuName("");
    setBmuName(bmu);
  };
  const closeDynamicModal = () => {
    setDynamicModalShow(false);
  };

  const remitClicked = async (bmu) => {
    setRemitModalShow(true);

    setBmuName(bmu);
  };
  const closeRemitModal = () => {
    setRemitModalShow(false);
  };
  return (
    <>
      {loading ? (
        <>
          <div style={{ textAlign: "center" }}>
            <p>{message}</p>
            <ClipLoader loading={loading} />{" "}
          </div>
        </>
      ) : (
        <>
          <div> {content} </div>
          <BodModal
            bodModalShow={bodModalShow}
            closeBodModal={closeBodModal}
            bmuName={bmuName}
          />
          <IndicativeModal
            indicModalShow={indicModalShow}
            closeIndicModal={closeIndicModal}
            bmuName={bmuName}
          />
          <DynamicDataModal
            dynamicModalShow={dynamicModalShow}
            closeDynamicModal={closeDynamicModal}
            bmuName={bmuName}
          ></DynamicDataModal>
          <RemitModal
            remitModalShow={remitModalShow}
            closeRemitModal={closeRemitModal}
            bmuName={bmuName}
          ></RemitModal>
        </>
      )}
    </>
  );
};

export default Bmu6;
