import React from "react";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { useParams } from "react-router-dom";
import MainContext from "../context/MainContext";
import { addDays, format } from "date-fns";
import { ClipLoader } from "react-spinners";

const GenByFuel = () => {
  const [fuelList, setFuelList] = useState([]);
  const [fuelData, setFuelData] = useState([]);
  const [content, setContent] = useState([]);

  const { id } = useParams();

  const { datePicker, loading, setLoading } = useContext(MainContext);

  useEffect(() => {
    const startDate = format(new Date(datePicker), "yyyy-MM-dd");
    const yr = new Date(startDate).getFullYear();
    const month = new Date(startDate).getMonth();
    const day = new Date(startDate).getDate();
    const endDate = format(new Date(addDays(datePicker, 1)), "yyyy-MM-dd");
    const getData = async () => {
      setLoading(true);
      const response = await fetch(
        //`https://data.elexon.co.uk/bmrs/api/v1/generation/outturn/summary?startTime=${startDate}&endTime=${endDate}&includeNegativeGeneration=true&format=json`
        `https://data.elexon.co.uk/bmrs/api/v1/generation/actual/per-type?from=${startDate}&to=${endDate}&format=json`
        //`http://localhost:3500/fuel/genalt/y/${yr}/m/${month}/d/${day}`
      );

      let data = await response.json();
      //console.log(data.data[0]);
      setFuelData(data);

      //const test = data.data[0].data.filter((x) => x.psrType === "Biomass");
      //console.log(fuelData);
      //console.log(test);

      /* let fuelType = [
        "CCGT",
        "BIOMASS",
        "NUCLEAR",
        "WIND",
        "PS",
        "NPSHYD",
        "OCGT",
        "OTHER",
        "OIL",
        "COAL",
        "INTFR",
        "INTIFA2",
        "INTELEC",
        "INTIRL",
        "INTEW",
        "INTNED",
        "INTNEM",
        "INTNSL",
        "INTVKL",
      ]; */
      let fuelType = [];
      for (let i = 0; i < data.data.length; i++) {
        //console.log(data.data.length);
        for (let a = 0; a < data.data[i].data.length; a++) {
          let fuel = data.data[i].data[a].psrType;
          //console.log(fuel);
          if (!fuelType.includes(fuel)) {
            fuelType.push(fuel);
          }
        }
        setFuelList(fuelType);
      }
      //console.log(fuelType);
    };
    getData();
  }, [datePicker]);

  useEffect(() => {
    const getData = (e) => {
      //console.log(e);

      let chartData = [
        [
          { type: "string", label: "Period" },
          { type: "number", label: "MW" },
        ],
      ];
      //console.log(fuelData);
      for (let a = 0; a < fuelData.data.length; a++) {
        const period = fuelData.data[a].data.filter((x) => x.psrType === e);
        //console.log(fuelData.data[a]);
        //console.log(period);
        let temp = [];
        temp.push(fuelData.data[a].settlementPeriod);
        temp.push(period[0].quantity);
        chartData.push(temp);
      }
      //console.log(chartData);
      var options = {
        title: e,
        width: 495,
        height: 300,
        series: {
          0: { lineWidth: 1, pointSize: 1.1 },
          1: { lineWidth: 1, pointSize: 1.1, type: "lines" },
          2: { lineWidth: 1, pointSize: 1.1, type: "lines" },
        },
        hAxis: {
          textStyle: { fontSize: 10 },
          title: "Period",
          //format: 'HH:mm',
          //minValue: new Date(y,m,d,0,0,0),
          // maxValue: new Date(y,m,d+1,0,0,0),
          minValue: 0,
          maxValue: 50,
          viewWindow: {
            //min: new Date(y,m,d,0,0,0),
            //max: new Date(y,m,d+1,0,0,0)
            //min: 1,
            max: 50,
          },
        },
        vAxis: {
          minValue: 0,
          textStyle: { fontSize: 10 },
        },
        chartArea: { backgroundColor: "#fffff0" },
      };

      return (
        <div
          key={e}
          style={{
            height: "330px",
            width: "500px",
            border: "1px solid #DCDCDC",
            float: "left",
          }}
        >
          <Chart chartType="LineChart" options={options} data={chartData} />
        </div>
      );
    };

    let promises = [];
    //console.log(bmuList);
    for (let i = 0; i < fuelList.length; i++) {
      //console.log(fuelList[i]);
      //getData(fuelList[i]);
      promises.push(getData(fuelList[i]));
    }
    //console.log(promises);
    Promise.all(promises).then((arrOfResults) => {
      setContent([]);
      for (let i = 0; i < arrOfResults.length; i++) {
        setContent((current) => [...current, arrOfResults[i]]);
        //console.log(content);
      }
      setLoading(false);
    });
  }, [fuelList]);

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader loading={loading} />{" "}
        </div>
      ) : (
        <div>{content}</div>
      )}
    </>
  );
};

export default GenByFuel;
