import React from "react";
import Modal from "react-modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MainContext from "../context/MainContext";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { addDays, format, getDate, getMonth } from "date-fns";

Modal.setAppElement("#root");

const PriceDetail = ({
  detailModalShow,
  closeDetailModal,
  sp,
  price,
  code,
}) => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [tableBid, setTableBid] = useState([]);
  const [tableOffer, setTableOffer] = useState([]);
  const [bmus, setBmus] = useState([]);
  const startDate = format(new Date(datePicker), "yyyy-MM-dd");

  useEffect(() => {
    fetch("https://data.elexon.co.uk/bmrs/api/v1/reference/bmunits/all")
      .then((response) => response.json())
      .then((data) => {
        // console.log(
        //   data.filter((x) => x.fuelType === id && x.fpnFlag === true)
        // );

        setBmus(data);
      });
  }, []);

  useEffect(() => {
    const getBids = async () => {
      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/stack/all/bid/${startDate}/${sp}?format=json`
      );
      let unfilteredData1 = await response.json();
      let data1 = unfilteredData1.data.sort(
        (a, b) => new Date(b.startTime) - new Date(a.startTime)
      );

      const response2 = await fetch(
        //`https://data.elexon.co.uk/bmrs/api/v1/balancing/nonbm/disbsad/details?settlementDate=2024-09-25&settlementPeriod=39&format=json`
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/nonbm/disbsad/details?settlementDate=${startDate}&settlementPeriod=${sp}&format=json`
      );
      let unfilteredData2 = await response2.json();
      let data2 = unfilteredData2.data;
      console.log(data2);

      const fontSize = {
        fontSize: "12px",
        backgroundColor: "#2e86c1",
        position: "sticky",
        top: "-10px",
      };
      const fontSize2 = { fontSize: "12px" };
      const buttonStyle = { fontSize: "12px", height: "18px" };
      const contentBid = (
        <table className="table table-condensed table-striped table-bordered">
          <thead style={{ position: "sticky top: 0" }}>
            <tr>
              <th style={fontSize}>Sequence Number</th>
              <th style={fontSize}>Id</th>
              <th style={fontSize}>Fuel or Trade Type</th>
              <th style={fontSize}>BMU Type or Asset Id</th>
              <th style={fontSize}>Lead Party Name</th>
              <th style={fontSize}>Acceptance Id</th>
              <th style={fontSize}>Bid Offer Pair Id</th>
              <th style={fontSize}>CADL Flag</th>
              <th style={fontSize}>SO Flag</th>
              <th style={fontSize}>Stor Provider Flag</th>
              <th style={fontSize}>Repriced Indicator</th>
              <th style={fontSize}>Reserve Scarcity Price</th>
              <th style={fontSize}>Original Price</th>
              <th style={fontSize}>Volume</th>
              <th style={fontSize}>DMAT Adjusted Volume</th>
              <th style={fontSize}>Arbitrage Adjusted Volume</th>

              <th style={fontSize}>NIV Adjusted Volume</th>
              <th style={fontSize}>PAR Adjusted Volume</th>
              <th style={fontSize}>Final Price</th>
              <th style={fontSize}>Transmission Loss Multiplier</th>
              <th style={fontSize}>TLM Adjusted Volume</th>
              <th style={fontSize}>TLM Adjusted Cost</th>
            </tr>
          </thead>
          <tbody>
            {data1?.map((data, i) => (
              <tr
                key={i}
                className={
                  data.finalPrice === price ? "table-warning" : "table-default"
                }
              >
                <td style={fontSize2}>{data.sequenceNumber}</td>
                {/* <td style={fontSize2}>{data.createdDateTime}</td> */}
                <td style={fontSize2}>{data.id}</td>
                <td style={fontSize2}>
                  {bmus.filter((x) => x.elexonBmUnit === data.id).length > 0
                    ? bmus.filter((x) => x.elexonBmUnit === data.id)[0].fuelType
                    : isNaN(Number(data.id))
                    ? null
                    : "DISBSAD"}
                </td>
                <td style={fontSize2}>
                  {bmus.filter((x) => x.elexonBmUnit === data.id).length > 0
                    ? bmus.filter((x) => x.elexonBmUnit === data.id)[0]
                        .bmUnitType
                    : isNaN(Number(data.id))
                    ? null
                    : data2.filter((x) => x.id === Number(data.id))[0]?.assetId}
                </td>
                <td style={fontSize2}>
                  {bmus.filter((x) => x.elexonBmUnit === data.id).length > 0
                    ? bmus.filter((x) => x.elexonBmUnit === data.id)[0]
                        .leadPartyName
                    : isNaN(Number(data.id))
                    ? null
                    : data2.filter((x) => x.id === Number(data.id))[0]?.partyId}
                </td>
                <td style={fontSize2}>{data.acceptanceId}</td>
                <td style={fontSize2}>{data.bidOfferPairId}</td>
                <td style={fontSize2}>{data.cadlFlag ? "True" : "False"}</td>
                <td style={fontSize2}>{data.soFlag ? "True" : "False"}</td>
                <td style={fontSize2}>
                  {data.storProviderFlag ? "True" : "False"}
                </td>
                <td style={fontSize2}>
                  {data.repricedIndicator ? "True" : "False"}
                </td>
                <td style={fontSize2}>
                  {data.reserveScarcityPrice === null
                    ? data.reserveScarcityPrice
                    : Math.round(data.reserveScarcityPrice * 1000) / 1000}
                </td>
                <td style={fontSize2}>
                  {data.originalPrice === null
                    ? data.originalPrice
                    : Math.round(data.originalPrice * 1000) / 1000}
                </td>
                <td style={fontSize2}>
                  {data.volume === null
                    ? data.volume
                    : Math.round(data.volume * 1000) / 1000}
                </td>
                <td style={fontSize2}>
                  {data.dmatAdjustedVolume === null
                    ? data.dmatAdjustedVolume
                    : Math.round(data.dmatAdjustedVolume * 1000) / 1000}
                </td>
                <td style={fontSize2}>
                  {data.arbitrageAdjustedVolume === null
                    ? data.arbitrageAdjustedVolume
                    : Math.round(data.arbitrageAdjustedVolume * 1000) / 1000}
                </td>
                <td style={fontSize2}>
                  {data.nivAdjustedVolume === null
                    ? data.nivAdjustedVolume
                    : Math.round(data.nivAdjustedVolume * 1000) / 1000}
                </td>
                <td style={fontSize2}>
                  {data.parAdjustedVolume === null
                    ? data.parAdjustedVolume
                    : Math.round(data.parAdjustedVolume * 1000) / 1000}
                </td>
                <td style={fontSize2}>
                  {data.finalPrice === null
                    ? data.finalPrice
                    : Math.round(data.finalPrice * 1000) / 1000}
                </td>
                <td style={fontSize2}>
                  {data.transmissionLossMultiplier === null
                    ? data.transmissionLossMultiplier
                    : Math.round(data.transmissionLossMultiplier * 10000) /
                      10000}
                </td>
                <td style={fontSize2}>
                  {data.tlmAdjustedVolume === null
                    ? data.tlmAdjustedVolume
                    : Math.round(data.tlmAdjustedVolume * 1000) / 1000}
                </td>
                <td style={fontSize2}>
                  {data.tlmAdjustedCost === null
                    ? data.tlmAdjustedCost
                    : Math.round(data.tlmAdjustedCost * 1000) / 1000}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      );
      setTableBid(contentBid);

      setLoading(false);
    };
    const getOffers = async () => {
      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/settlement/stack/all/offer/${startDate}/${sp}?format=json`
      );
      let unfilteredData1 = await response.json();
      let data1 = unfilteredData1.data.sort(
        (a, b) => new Date(b.startTime) - new Date(a.startTime)
      );
      console.log(data1);
      const response2 = await fetch(
        //`https://data.elexon.co.uk/bmrs/api/v1/balancing/nonbm/disbsad/details?settlementDate=2024-09-25&settlementPeriod=39&format=json`
        `https://data.elexon.co.uk/bmrs/api/v1/balancing/nonbm/disbsad/details?settlementDate=${startDate}&settlementPeriod=${sp}&format=json`
      );
      let unfilteredData2 = await response2.json();
      let data2 = unfilteredData2.data;
      const fontSize = {
        fontSize: "12px",
        backgroundColor: "#2e86c1",
        position: "sticky",
        top: "-10px",
      };
      const fontSize2 = { fontSize: "12px" };
      const buttonStyle = { fontSize: "12px", height: "18px" };
      const contentOffer = (
        <div className="container-fluid">
          <div className="overflow-y:auto">
            <table className="table table-condensed table-striped table-bordered">
              <thead style={{ position: "sticky top: 0" }}>
                <tr>
                  <th style={fontSize}>Sequence Number</th>
                  <th style={fontSize}>Id</th>
                  <th style={fontSize}>Fuel or Trade Type</th>
                  <th style={fontSize}>BMU Type or Asset Id</th>
                  <th style={fontSize}>Lead Party Name</th>
                  <th style={fontSize}>Acceptance Id</th>
                  <th style={fontSize}>Bid Offer Pair Id</th>
                  <th style={fontSize}>CADL Flag</th>
                  <th style={fontSize}>SO Flag</th>
                  <th style={fontSize}>Stor Provider Flag</th>
                  <th style={fontSize}>Repriced Indicator</th>
                  <th style={fontSize}>Reserve Scarcity Price</th>
                  <th style={fontSize}>Original Price</th>
                  <th style={fontSize}>Volume</th>
                  <th style={fontSize}>DMAT Adjusted Volume</th>
                  <th style={fontSize}>Arbitrage Adjusted Volume</th>

                  <th style={fontSize}>NIV Adjusted Volume</th>
                  <th style={fontSize}>PAR Adjusted Volume</th>
                  <th style={fontSize}>Final Price</th>
                  <th style={fontSize}>Transmission Loss Multiplier</th>
                  <th style={fontSize}>TLM Adjusted Volume</th>
                  <th style={fontSize}>TLM Adjusted Cost</th>
                </tr>
              </thead>
              <tbody>
                {data1?.map((data, i) => (
                  <tr
                    key={i}
                    className={
                      data.finalPrice === price
                        ? "table-warning"
                        : "table-default"
                    }
                  >
                    <td style={fontSize2}>{data.sequenceNumber}</td>
                    {/* <td style={fontSize2}>{data.createdDateTime}</td> */}
                    <td style={fontSize2}>{data.id}</td>
                    <td style={fontSize2}>
                      {bmus.filter((x) => x.elexonBmUnit === data.id).length > 0
                        ? bmus.filter((x) => x.elexonBmUnit === data.id)[0]
                            .fuelType
                        : isNaN(Number(data.id))
                        ? null
                        : "DISBSAD"}
                    </td>
                    <td style={fontSize2}>
                      {bmus.filter((x) => x.elexonBmUnit === data.id).length > 0
                        ? bmus.filter((x) => x.elexonBmUnit === data.id)[0]
                            .bmUnitType
                        : isNaN(Number(data.id))
                        ? null
                        : data2.filter((x) => x.id === Number(data.id))[0]
                            ?.assetId}
                    </td>
                    <td style={fontSize2}>
                      {bmus.filter((x) => x.elexonBmUnit === data.id).length > 0
                        ? bmus.filter((x) => x.elexonBmUnit === data.id)[0]
                            .leadPartyName
                        : isNaN(Number(data.id))
                        ? null
                        : data2.filter((x) => x.id === Number(data.id))[0]
                            ?.partyId}
                    </td>
                    <td style={fontSize2}>{data.acceptanceId}</td>
                    <td style={fontSize2}>{data.bidOfferPairId}</td>
                    <td style={fontSize2}>
                      {data.cadlFlag ? "True" : "False"}
                    </td>
                    <td style={fontSize2}>{data.soFlag ? "True" : "False"}</td>
                    <td style={fontSize2}>
                      {data.storProviderFlag ? "True" : "False"}
                    </td>
                    <td style={fontSize2}>
                      {data.repricedIndicator ? "True" : "False"}
                    </td>
                    <td style={fontSize2}>
                      {data.reserveScarcityPrice === null
                        ? data.reserveScarcityPrice
                        : Math.round(data.reserveScarcityPrice * 1000) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.originalPrice === null
                        ? data.originalPrice
                        : Math.round(data.originalPrice * 1000) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.volume === null
                        ? data.volume
                        : Math.round(data.volume * 1000) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.dmatAdjustedVolume === null
                        ? data.dmatAdjustedVolume
                        : Math.round(data.dmatAdjustedVolume * 1000) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.arbitrageAdjustedVolume === null
                        ? data.arbitrageAdjustedVolume
                        : Math.round(data.arbitrageAdjustedVolume * 1000) /
                          1000}
                    </td>
                    <td style={fontSize2}>
                      {data.nivAdjustedVolume === null
                        ? data.nivAdjustedVolume
                        : Math.round(data.nivAdjustedVolume * 1000) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.parAdjustedVolume === null
                        ? data.parAdjustedVolume
                        : Math.round(data.parAdjustedVolume * 1000) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.finalPrice === null
                        ? data.finalPrice
                        : Math.round(data.finalPrice * 1000) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.transmissionLossMultiplier === null
                        ? data.transmissionLossMultiplier
                        : Math.round(data.transmissionLossMultiplier * 10000) /
                          10000}
                    </td>
                    <td style={fontSize2}>
                      {data.tlmAdjustedVolume === null
                        ? data.tlmAdjustedVolume
                        : Math.round(data.tlmAdjustedVolume * 1000) / 1000}
                    </td>
                    <td style={fontSize2}>
                      {data.tlmAdjustedCost === null
                        ? data.tlmAdjustedCost
                        : Math.round(data.tlmAdjustedCost * 1000) / 1000}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
      setTableOffer(contentOffer);

      setLoading(false);
    };

    getBids();
    getOffers();
  }, [datePicker, sp]);

  const customStyles = {
    content: {
      top: "5%",
      left: "0%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      //transform: "translate(-50%, -50%)",
    },
  };

  return (
    <Modal isOpen={detailModalShow}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-11">
                  <b>
                    Settlement Period - {sp}, Settlement Period Price - {price}{" "}
                    £/MWhr Price Code - {code}&nbsp; &nbsp; &nbsp; &nbsp; (Note
                    - Code N, Bids set price. Code P, Offers set Price)
                    <br />
                    <br />
                  </b>
                  <b>Click Tabs below to see data</b>
                </div>
                <div className="col-md-1 text-end ">
                  <button
                    className="btn btn-primary "
                    onClick={closeDetailModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="Bids" title="Bids">
                {tableBid}
              </Tab>
              <Tab eventKey="Offers" title="Offers">
                {tableOffer}
              </Tab>
            </Tabs>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={closeDetailModal}>
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PriceDetail;
