import React from "react";
import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import MainContext from "../context/MainContext";
import { addDays, format, getDate, getMonth } from "date-fns";
import { ClipLoader } from "react-spinners";
import { getTimezoneOffset } from "date-fns-tz";

const MarketIndexData = () => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const localStartDate = format(new Date(datePicker), "yyyy-MM-dd");
      let startDateTzDiff =
        getTimezoneOffset("Europe/Dublin", localStartDate) / 1000 / 60 / 60;
      const localEndDate = format(
        new Date(addDays(datePicker, 1)),
        "yyyy-MM-dd"
      );
      let endDateTzDiff =
        getTimezoneOffset("Europe/Dublin", localEndDate) / 1000 / 60 / 60;

      let startDate;
      let endDate;
      if (startDateTzDiff === 1) {
        startDate =
          format(new Date(addDays(localStartDate, -1)), "yyyy-MM-dd") +
          "T23%3A00z";
      } else {
        startDate =
          format(new Date(localStartDate), "yyyy-MM-dd") + "T00%3A00z";
      }
      if (endDateTzDiff === 1) {
        endDate =
          format(new Date(addDays(localEndDate, -1)), "yyyy-MM-dd") +
          "T22%3A30z";
      } else {
        endDate =
          format(new Date(addDays(localEndDate, -1)), "yyyy-MM-dd") +
          "T23%3A30z";
      }
      const response = await fetch(
        `https://data.elexon.co.uk/bmrs/api/v1/datasets/MID/stream?from=${startDate}&to=${endDate}&dataProviders=APXMIDP`
      );
      let data1 = await response.json();
      console.log(data1);
      const headerStyle = {
        position: "sticky",
        top: "50px",
        backgroundColor: "#2e86c1",
      };
      const content = (
        <>
          <div className={"container"}>
            <table className="table table-condensed table-striped table-bordered">
              <thead style={{ position: "sticky top: 0" }}>
                <tr>
                  <th style={headerStyle}>Settlement Period </th>
                  <th style={headerStyle}>Start Date Time</th>
                  <th style={headerStyle}>Volume</th>
                  <th style={headerStyle}>Price</th>
                </tr>
              </thead>
              <tbody>
                {data1.map((data, i) => (
                  <tr>
                    <td>{data.settlementPeriod}</td>
                    <td>
                      {format(new Date(data.startTime), "yyyy-MM-dd HH:mm")}
                    </td>
                    <td>{data.volume}</td>
                    <td>{data.price}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      );
      setTableData(content);
      setLoading(false);
    };
    getData();
  }, [datePicker]);

  return (
    <>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <ClipLoader loading={loading} />{" "}
        </div>
      ) : (
        <div>
          <div>{tableData}</div>
        </div>
      )}
    </>
  );
};

export default MarketIndexData;
