import React from "react";
import Modal from "react-modal";
import MainContext from "../context/MainContext";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { addDays, format, getDate, getMonth } from "date-fns";
import acceptanceHelper from "../helpers/acceptance2Helper";

Modal.setAppElement("#root");

const IndicativeModal2 = ({ indicModalShow, closeIndicModal, bmuName }) => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [indicVolData, setIndicVolData] = useState("");
  const [indicCashData, setIndicCashData] = useState("");
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const updateTableData = async () => {
      const startDate = format(new Date(datePicker), "yyyy-MM-dd");
      let bidOffer = await acceptanceHelper.acceptance(bmuName, startDate);
      console.log(bidOffer);

      const data = [
        [
          "SP",
          "Pair ID",
          "Bid Vol",
          "Bid Cash",
          "Bid Price",
          "Offer Vol",
          "Offer Cash",
          "Offer Price",
        ],
      ];
      let temp = [];
      for (let a = 0; a < bidOffer.length; a++) {
        console.log(bidOffer[a]);
        if (
          ((Math.round(bidOffer[a][2] * 1000) / 1000 !== 0 &&
            bidOffer[a][2] !== null) ||
            (Math.round(bidOffer[a][3] * 1000) / 1000 !== 0 &&
              bidOffer[a][3] !== null) ||
            (Math.round(bidOffer[a][5] * 1000) / 1000 !== 0 &&
              bidOffer[a][5] !== null) ||
            (Math.round(bidOffer[a][6] * 1000) / 1000 !== 0 &&
              bidOffer[a][6] !== null)) &&
          bidOffer[a][4] !== undefined &&
          bidOffer[a][7] !== undefined
        ) {
          temp = [];
          temp.push(bidOffer[a][0]);
          temp.push(bidOffer[a][1]);
          temp.push(bidOffer[a][2]);
          temp.push(bidOffer[a][3]);
          temp.push(bidOffer[a][4]);
          temp.push(bidOffer[a][5]);
          temp.push(bidOffer[a][6]);
          temp.push(bidOffer[a][7]);
          data.push(temp);
        }
      }
      setTableData(data);
    };

    updateTableData();
  }, [bmuName]);

  const data2 = [
    ["Department", "Revenues Change"],
    ["Shoes", 10700],
    ["Sports", -15400],
    ["Toys", 12500],
    ["Electronics", -2100],
    ["Food", 22600],
    ["Art", 1100],
  ];
  const options = {
    allowHtml: true,
    showRowNumber: false,
  };

  const formatters = [
    {
      column: 1,
      options: {
        prefix: "$",
        negativeColor: "red",
        negativeParens: true,
      },
    },
  ];

  const customStyles = {
    content: {
      top: "15%",
      left: "20%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      //transform: "translate(-50%, -50%)",
    },
  };

  return (
    <Modal isOpen={indicModalShow} style={customStyles}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">{bmuName}</div>
          <div className="modal-body">
            <div style={{ float: "left" }}>
              <Chart
                chartType="Table"
                width="100%"
                height="400px"
                data={tableData}
                options={options}
                formatters={formatters}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={closeIndicModal}>
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default IndicativeModal2;
