import { createContext, useState } from "react";
import React from "react";
import { format } from "date-fns";

const MainContext = createContext({});

export const MainProvider = ({ children }) => {
  const [datePicker, SetDatePicker] = useState(
    format(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      ),
      "yyy-MM-dd"
    )
  );
  const [loading, setLoading] = useState(false);
  return (
    <MainContext.Provider
      value={{
        datePicker,
        SetDatePicker,
        loading,
        setLoading,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export default MainContext;
