import React from "react";
import Modal from "react-modal";
import MainContext from "../context/MainContext";
import { useState, useEffect, useContext } from "react";
import { Chart } from "react-google-charts";
import { addDays, format, getDate, getMonth } from "date-fns";

Modal.setAppElement("#root");

const BodModal = ({ bodModalShow, closeBodModal, bmuName }) => {
  const { datePicker, loading, setLoading } = useContext(MainContext);
  const [bodData, setBodData] = useState("");
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const startDate = format(new Date(datePicker), "yyyy-MM-dd");
    const endDate = format(new Date(addDays(datePicker, 1)), "yyyy-MM-dd");
    //console.log(bmuName);
    //console.log(startDate);
    //console.log(endDate);
    fetch(
      //`https://data.elexon.co.uk/bmrs/api/v1/balancing/bid-offer?bmUnit=T_DRAXX-1&from=2024-09-08T00%3A00z&to=2024-09-09T00%3A00z&format=json`
      `https://data.elexon.co.uk/bmrs/api/v1/balancing/bid-offer?bmUnit=${bmuName}&from=${startDate}T00%3A00z&to=${endDate}T00%3A00z&format=json`
    )
      .then((response) => response.json())
      .then((data) => {
        setBodData(data.data);
      });
  }, [bmuName]);

  useEffect(() => {
    //console.log(bodData);
    //console.log(bodData.length);
    const data = [
      [
        "Time From",
        "Time To",
        "SP",
        "Pair ID",
        "Level From",
        "Level To",
        "Bid",
        "Offer",
      ],
    ];
    //console.log(bodData);
    let temp = [];
    for (let a = 0; a < bodData.length; a++) {
      temp = [];
      temp.push(bodData[a].timeFrom);
      temp.push(bodData[a].timeTo);
      temp.push(bodData[a].settlementPeriod);
      temp.push(bodData[a].pairId);
      temp.push(bodData[a].levelFrom);
      temp.push(bodData[a].levelTo);
      temp.push(bodData[a].bid);
      temp.push(bodData[a].offer);
      data.push(temp);
    }
    setTableData(data);
    //console.log(bodData);
    //console.log(data);
  }, [bodData]);

  const data2 = [
    ["Department", "Revenues Change"],
    ["Shoes", 10700],
    ["Sports", -15400],
    ["Toys", 12500],
    ["Electronics", -2100],
    ["Food", 22600],
    ["Art", 1100],
  ];
  const options = {
    allowHtml: true,
    showRowNumber: false,
  };

  const formatters = [
    {
      column: 1,
      options: {
        prefix: "$",
        negativeColor: "red",
        negativeParens: true,
      },
    },
  ];

  const customStyles = {
    content: {
      top: "15%",
      left: "20%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      //transform: "translate(-50%, -50%)",
    },
  };

  return (
    <Modal isOpen={bodModalShow} style={customStyles}>
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">{bmuName}</div>
          <div className="modal-body">
            <Chart
              chartType="Table"
              width="100%"
              height="400px"
              data={tableData}
              options={options}
              formatters={formatters}
            />
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary" onClick={closeBodModal}>
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BodModal;
